import './index.scss';
import './index-client.scss';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import ProductInfoPage from './pages/product/ProductInfoPage';
import FullscreenPolyfill from 'fullscreen-polyfill'
import TerminalMonitoringPage from './pages/monitoring/TerminalMonitoringPage';
import ClientMonitoringPage from './pages/monitoring/ClientMonitoringPage';
import useScript from './util/useScript';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import HTMLEditorPage from './html-editor/HTMLEditorPage';
import { hasCapabilitySupport } from './app/Capabilities';
import { LmsQrCodeScanner } from './pages/attendance-managment/lms-qr-code-scanner';
import { MovieTicketQrCodeScanner } from './pages/attendance-managment/movie-ticket-qr-code-scanner';
import { CinemaDemo } from './demo/cinema-demo';
import { CinemaDemoMoviePage } from './demo/cinema-demo/movie';
import { CinemaDemoOrderPage } from './demo/cinema-demo/order';
import { CinemaDemoTicketSalePage } from './demo/cinema-demo/ticket-sale';
import { ShipmentJobQr } from './pages/shipping/qr-code/shipment-job-qr';
import { ShipmentMasterQr } from './pages/shipping/qr-code/shipment-master-qr';
import { ShipmentPackageQr } from './pages/shipping/qr-code/shipment-package-qr';
import { ShipmentPackageItemQr } from './pages/shipping/qr-code/shipment-package-item-qr';
import { CinemaSeatQrCode } from './pages/cinema/qr-code/seat-qr';
import { ClientTicketSale } from './pages/cinema/qr-code/client-ticket-sale';
import { isV2 } from './util/Util';
import { App2 } from './2v/app2';
import { CalendarTools32 } from '@carbon/icons-react'
import { ListSelector, createListSelector } from './components/list-selector';
import Api, { createApi } from './session/Api';
import { OBJECT_TYPE_PRODUCT } from './constants/ObjectTypes';
import ProfilePic from './components/ProfilePic';
import { isAdidas } from './app/app-util';
import { AdidasTrackingApp } from './pages/sales-system/apps/adidas-tracking';
import { getAccount, isLoggedIn } from './session/SessionManager';
import { CargoCustomerApp } from './cargo-customer';
// import CustomerCardPage from './pages/customer-card-page/CustomerCardPage';

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

const underMaintenance = false;



const Index = props => {
    let clientMonitor = window.location.pathname.includes("/client-monitor") || (window.location.pathname.includes("/monitor") && !window.location.pathname.includes("/monitoring"));
    let qrCodeScanner = window.location.pathname.includes("/attendance-qr-code-scanner")

    // React.useEffect(() => {
    //     //const key = hasCapabilitySupport("shipping") || isV2() ? "cd10" : "cd9";
    //     const key = "cdFN"

    //     let cleared = false;
    //     if (localStorage.getItem(key) != 'true') {
    //         localStorage.clear();
    //         cleared = true;
    //     }
    //     localStorage.setItem(key, 'true');
    //     if (cleared) {
    //         window.location.reload();
    //     }
    // }, []);

    useEffect(() => {
        const listener = e => {
            if (e.target.nodeName === "INPUT" && e.target.type === "number") {
                e.target.blur();
            }
        }
        document.addEventListener('wheel', listener);
        return () => document.removeEventListener(listener);
    }, [])

    useEffect(() => {
        if (isLoggedIn()) {
            createApi(api => {
                api.showLogoutMsg = false;
            }).isLoggedIn(() => { })
        }
    }, [])

    const [value, setValue] = useState(undefined);
    const accountType = useMemo(() => getAccount()?.type, [])

    if (underMaintenance) {
        if (localStorage.getItem("robo-main-key") !== "jkfhw@fue3wfh2") {
            //(() => {localStorage.setItem("robo-main-key", "jkfhw@fue3wfh2"); window.location.reload()})();
            return (
                <React.StrictMode>
                    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0rem', flexDirection: 'column' }}>
                        <CalendarTools32 />
                        <h4 style={{ marginTop: '1rem' }}>System Under Maintenance</h4>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>In progress</p>
                    </div>
                </React.StrictMode>
            )
        }
    }

    if (accountType === 'CARGO_CUSTOMER') {
        return (
            <React.StrictMode>
                <CargoCustomerApp />
            </React.StrictMode>
        )
    }

    return (
        <React.StrictMode>
            <div className={(clientMonitor || qrCodeScanner) ? "client-monitor" : undefined} style={(clientMonitor || qrCodeScanner) ? {
                width: '100%', //minHeight: '100vh', minHeight: 'fill-available',
                //minHeight: '-webkit-fill-available', 
                display: 'flex', flexDirection: 'column',
            } : {
                width: '100%', height: '100vh'
            }}>
                <BrowserRouter>
                    {(isV2()) ? (
                        <Switch>
                            <Route exact path="/html-editor/:targetType/:targetId" component={HTMLEditorPage} />
                            <Route exact path="/html-editor/:targetType/:targetId/:lang" component={HTMLEditorPage} />

                            <Route component={App2} />
                        </Switch>
                    ) : (
                        <Switch>
                            <Route exact path="/html-editor/:targetType/:targetId" component={HTMLEditorPage} />
                            <Route exact path="/html-editor/:targetType/:targetId/:lang" component={HTMLEditorPage} />

                            {/* <Route exact path="/monitor" component={TerminalMonitoringPage} /> */}
                            <Route exact path="/client-monitor/:terminalId/:cartId" component={ClientMonitoringPage} />

                            <Route exact path="/product-preview/:itemId" component={ProductInfoPage} />

                            {hasCapabilitySupport("lms") &&
                                <Route exact path="/attendance-qr-code-scanner" component={LmsQrCodeScanner} />}

                            {hasCapabilitySupport("cinema") &&
                                <Route exact path="/movie-ticket-qr-code-scanner" component={MovieTicketQrCodeScanner} />}
                            {hasCapabilitySupport("cinema") &&
                                <Route exact path="/cinema-seat-qr/:seatPlanId/:row/:col" component={CinemaSeatQrCode} />}
                            {hasCapabilitySupport("cinema") &&
                                <Route exact path="/ticket/:serviceSaleId" component={ClientTicketSale} />}

                            {hasCapabilitySupport("shipping") &&
                                <Route exact path="/shipment-job-qr/:shipmentId" component={ShipmentJobQr} />}
                            {hasCapabilitySupport("shipping") &&
                                <Route exact path="/shipment-master-qr/:masterId" component={ShipmentMasterQr} />}
                            {hasCapabilitySupport("shipping") &&
                                <Route exact path="/shipment-package-qr/:packageId" component={ShipmentPackageQr} />}
                            {hasCapabilitySupport("shipping") &&
                                <Route exact path="/shipment-package-item-qr/:itemId" component={ShipmentPackageItemQr} />}

                            {isAdidas() &&
                                <Route exact path="/sw-tracking/:id" component={AdidasTrackingApp} />}


                            {hasCapabilitySupport("cinema") && <Route exact path="/cinema-web" component={CinemaDemo} />}
                            {hasCapabilitySupport("cinema") && <Route exact path="/cinema-web/:movieId" component={CinemaDemoMoviePage} />}
                            {hasCapabilitySupport("cinema") && <Route exact path="/cinema-web/:movieId/:showtimeId" component={CinemaDemoOrderPage} />}
                            {hasCapabilitySupport("cinema") && <Route exact path="/cinema-web-s/ticket-sale/:serviceSaleId" component={CinemaDemoTicketSalePage} />}

                            <Route component={App} />
                        </Switch>
                    )}
                </BrowserRouter>
                <Toaster />
                <ToastContainer />
            </div>
        </React.StrictMode>
    )
}

ReactDOM.render(<Index />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
