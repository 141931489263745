import React, { createRef } from 'react'
import Page from '../../base/Page';

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Number_632,
    Number_732,
    Number_832,
    Number_932,
    Delivery32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    CheckboxIndeterminate32,
    Save16,
    Reset16,
    FlowStream16,
    Product16,
    LicenseDraft16,
    ErrorFilled16,
    Close16,
    Percentage16,
    Van32,
    ListChecked16,
    CutOut16,
    CutOut32,
    CheckmarkFilled16,
    Copy16,
    Building32,
    BuildingInsights_132,
    Xls16,
} from '@carbon/icons-react'
import { TileGroup, RadioTile, ComboBox, ButtonSet, Link, NumberInput, TextInput, DatePicker, DatePickerInput, ComposedModal, ModalHeader, ModalBody, ModalFooter, Checkbox, ContentSwitcher, Switch, Loading, Tag } from 'carbon-components-react';

import Button from '../../components/Button';
import { DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_STOCK_TERMINATION, DESTINATION_TYPE_STORE, DESTINATION_TYPE_SUPPLIER, DESTINATION_TYPE_WAREHOUSE, DESTINATION_TYPE_MOBILE_UNIT, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_WAREHOUSE, SOURCE_TYPE_MOBILE_UNIT, STATE_TYPE_STOCK_FLOW_CREATOR, SOURCE_TYPE_VENUE, DESTINATION_TYPE_VENUE, LEDGER_TYPE_EXPENSE } from '../../constants/Constants';
import StockItemEditor from './StockItemEditor';
import Api from '../../session/Api';
import Util, { big, isV2 } from '../../util/Util';
import UIUtil from '../../util/UIUtil';
import TransactionEditor from '../transaction/TransactionEditor';
import { withRouter } from 'react-router-dom';
import StockFlowField from './StockFlowField';
import ItemCreatorDialog from '../../templates/ItemCreatorDialog';
import { OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from '../../constants/ObjectTypes';
import { printProductLabels } from '../../pdfs/product-labels/ProductLabelsPdf';
import MyStateDraftsDialog from '../../views/state-drafts/MyStateDraftsDialog';
import { getAndExportTemplate } from '../label-designer/export/TemplateExporter';
import { TEMPLATE_TYPE_LABEL_GENERIC, TEMPLATE_TYPE_PRODUCT_LABEL } from '../label-designer/templates/Template';
import { getDefaultTemplate } from '../label-designer/util/TemplateDefaultFinder';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { getTerminal } from '../../session/SessionManager';
import ProductFinder from '../../views/product/ProductFinder';
import { TaxInput } from './TaxInput';
import StockFlowUtil from '../../stock-system/StockFlowUtil';
import { isSqlAvgEngine } from '../../stock-system/stock-engine';
import CustomComboBox from '../../components/CustomComboBox';
import { SalePreview } from './sale-preview';
import { ImportItemDialog } from './import-item-dialog';
import { ComboBoxField } from '../../components/ComboBoxField';
import { AssignedStockRequests } from './assigned-stock-requests';
import { CustomDialogSelectField } from '../../components/customer-dialog-select-field';

const Section = ({ children, icon, title, extraTopMargin, hasLink, hasButton, linkText, onLinkClick, rightComponent }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p style={{ flex: (hasButton || rightComponent) ? 1 : undefined }}>{title}</p>


            {hasLink && <Link style={{ marginLeft: '1rem', fontSize: 12, cursor: 'pointer' }} onClick={onLinkClick}>{linkText}</Link>}
            {hasButton && <Button renderIcon={Product16} onClick={onLinkClick}>{linkText}</Button>}
            {rightComponent}
        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => hasCapabilitySupport("mobileUnit") ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {React.createElement(icon)}
        <h4 style={{ marginTop: '0.5rem', textAlign: 'center' }}>{title}</h4>
        <p style={{ marginTop: '0rem', textAlign: 'center', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)

// const PAYMENT_MODE_TRANSACTION = 0;
// const PAYMENT_MODE_COUPON = 1;

// const PaymentModeSelector = ({ paymentMode, onPaymentModeChanged }) => (
//     <ContentSwitcher style={{ width: 300 }} selectedIndex={paymentMode} onChange={e => onPaymentModeChanged(e.index)}>
//         <Switch text="Transaction" />
//         <Switch text="Coupon" />
//     </ContentSwitcher>
// )
// const PaymentCoupon = ({ amount }) => (
//     <>
//         <div style={{display: 'flex', alignItems: 'center'}}>
//             <CutOut16 style={{marginRight: '0.5rem'}} />
//             <h6>Generating Coupon</h6>
//         </div>
//         <h2>AED {amount}</h2>
//         <p style={{marginTop: '0rem', fontSize: 14,}}>
//             A coupon with the amount of <strong>AED {amount}</strong> will be generated for the customer
//         </p>
//     </>
// )


const showGeneratedCouponsForRefund = stockFlowId => {
    Api.getStockFlowGeneratedCoupons(stockFlowId, response => {
        if (response.status === true && response.payload.length > 0) {
            UIUtil.openDialog(({ onClose }) => (
                <div style={{ background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden' }}>
                    {/* <ModalHeader label="Cash" title="Are you sure?" /> */}
                    <p style={{ color: '#0f62fe', textAlign: 'center', width: '100%', marginTop: '1rem' }}>Refund successful</p>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '3rem', marginBottom: '3rem' }}>
                        <CutOut32 style={{ fill: '#0f62fe', width: 64, height: 64 }} />
                        <h4 style={{ color: '#0f62fe' }}>Generated Coupon</h4>
                        <div style={{ marginTop: '3rem' }} />

                        {response.payload.map(coupon => (
                            <div key={coupon.code} style={{ display: 'flex', alignItems: 'center', width: '100%', paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                                <p style={{ flex: 1 }}>AED {coupon.amount}</p>
                                <p style={{ marginRight: '1rem' }}>{coupon.code}</p>
                                <Button kind="secondary" hasIconOnly renderIcon={Copy16} iconDescription="Copy Code" onClick={() => {
                                    if (navigator.clipboard) {
                                        navigator.clipboard.writeText(coupon.code)
                                            .then(() => UIUtil.showSuccess("Copied code!"))
                                            .catch(() => UIUtil.showInfo("Copying not supported on device"));
                                    } else {
                                        UIUtil.showInfo("Copying not supported on device")
                                    }
                                }} />
                            </div>
                        ))}
                    </div>
                    {/* <div style={{height: 15}} /> */}
                    <ButtonSet style={{ justifyContent: 'flex-end' }}>
                        <Button onClick={onClose} renderIcon={CheckmarkFilled16}>
                            OK
                        </Button>
                    </ButtonSet>
                </div>
            ))
        }
    })
}

class StockFlowCreatorPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            sourceTypeValue: this.getDefaultSourceTypeValue(),//SOURCE_TYPE_SUPPLIER + "",
            destinationTypeValue: this.getDefaultDestinationTypeValue(),//DESTINATION_TYPE_STORE,

            sourceCreationAdjustmentAccountObject: undefined,
            destinationTerminationCostObject: undefined,

            sourceIdValue: 0,
            destinationIdValue: this.getDefaultDestinationIdValue(),
            returnFlowParentIdValue: 0,

            amountValue: 0,
            amountAdditionalCostsValue: 0,
            salesAmountDiscountValue: 0,
            salesAmountTaxValue: 0,
            isQuotationValue: false,

            itemsValue: [],
            paymentMethodsValue: [],
            stockRequestsValue: [],

            purchaseSupplierInvoiceNoValue: "",
            purchaseSupplierInvoiceDateValue: null,
            salespersonIdValue: 0,



            sourceIdPickerKey: Util.newTempId(),
            destinationIdPickerKey: Util.newTempId(),
            itemsPickerKey: Util.newTempId(),
            paymentMethodPickerKey: Util.newTempId(),
            supplierDateKey: Util.newTempId(),


            returnFlowParentIdRequest: undefined,

            printProductLabels: false,
            labelType: "Barcode only",
            printPerQuantity: true,
            displayPrice: false,
            displaySku: false,
            labelAdvanced: true,
            labelSelectedTemplateId: undefined,


            accountTree: [],
            suppliers: [],
            customers: [],
            warehouses: [],
            stores: [],
            venues: [],
            salespeople: [],
            invBatchNos: [],

            mobileUnits: [],
            taxRate: 0,
            labelTemplates: [],
            allowSalesReturnWithoutOriginalSales: false,
            requireSupplierInvoiceNoAndDate: false,

            creatingFlow: false,
            showNoTransactionNoticeDialog: false,
            showCreateFlowConfirmDialog: false,

            showCreateProductDialog: false,

            showDrafts: false,
            showSaveDraftDialog: false,
            saveDraftTitleValue: "",
            savingDraft: false,

            selectedDraft: undefined,
            showImportItemsDialog: false
        }

        if (props.location.defaultFlow != undefined) {
            this.state = {
                ...this.state,
                ...props.location.defaultFlow
            }
        }

        this.stockItemEditorRef = createRef();
    }

    closeSelectedDraft() {
        this.resetToDefaults({
            selectedDraft: undefined,
        })
    }

    loadDraft(draft) {
        this.setStateFromDraft(draft);
    }

    saveUpdateDraft() {
        const draft = this.state.selectedDraft ? {
            ...this.state.selectedDraft,
            state: JSON.stringify(this.getStateForDraft())
        } : {
            stateType: STATE_TYPE_STOCK_FLOW_CREATOR,
            title: this.state.saveDraftTitleValue,
            state: JSON.stringify(this.getStateForDraft())
        }

        this.setState({ savingDraft: true });
        Api.saveStateDraft(draft, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                this.setState({ savingDraft: false, showSaveDraftDialog: false, selectedDraft: response.payload });
            } else {
                UIUtil.showError();
                this.setState({ savingDraft: false, });
            }
        })
    }

    getStateForDraft() {
        return {
            sourceTypeValue: this.state.sourceTypeValue,
            destinationTypeValue: this.state.destinationTypeValue,
            sourceIdValue: this.state.sourceIdValue,
            destinationIdValue: this.state.destinationIdValue,
            returnFlowParentIdValue: this.state.returnFlowParentIdValue,
            amountValue: this.state.amountValue,
            amountAdditionalCostsValue: this.state.amountAdditionalCostsValue,
            salesAmountDiscountValue: this.state.salesAmountDiscountValue,
            salesAmountTaxValue: this.state.salesAmountTaxValue,
            isQuotationValue: this.state.isQuotationValue,
            itemsValue: this.state.itemsValue,
            paymentMethodsValue: this.state.paymentMethodsValue,
            stockRequestsValue: this.state.stockRequestsValue,
            purchaseSupplierInvoiceNoValue: this.state.purchaseSupplierInvoiceNoValue,
            purchaseSupplierInvoiceDateValue: this.state.purchaseSupplierInvoiceDateValue,
            salespersonIdValue: this.state.salespersonIdValue,
            returnFlowParentIdRequest: this.state.returnFlowParentIdRequest,
            printProductLabels: this.state.printProductLabels,
            labelType: this.state.labelType,
            printPerQuantity: this.state.printPerQuantity,
            displayPrice: this.state.displayPrice,
            displaySku: this.state.displaySku,
            labelAdvanced: this.state.labelAdvanced,
            labelSelectedTemplateId: this.state.labelSelectedTemplateId,
        }
    }

    async setStateFromDraft(draft) {
        const draftState = JSON.parse(draft.state);
        const stopLoading = await UIUtil.pageLoader()
        try {
            const itemsDraft = draftState.itemsValue.filter($ => Util.isNumberExist($.itemId) && Util.isNumberExist($.itemType));

            const itemsToLoad = itemsDraft.map($ => ({ id: $.itemId, type: $.itemType }))
            const loadedItems = await Api.asyncGetManyProductMini(itemsToLoad);
            const getLoadedItem = item => loadedItems.find($ => $.id === item.itemId)

            this.setState({
                showDrafts: false,
                selectedDraft: draft,

                sourceTypeValue: draftState.sourceTypeValue,
                destinationTypeValue: draftState.destinationTypeValue,
                sourceIdValue: draftState.sourceIdValue,
                destinationIdValue: draftState.destinationIdValue,
                returnFlowParentIdValue: draftState.returnFlowParentIdValue,
                amountValue: draftState.amountValue,
                amountAdditionalCostsValue: draftState.amountAdditionalCostsValue,
                salesAmountDiscountValue: draftState.salesAmountDiscountValue,
                salesAmountTaxValue: draftState.salesAmountTaxValue,
                isQuotationValue: draftState.isQuotationValue,
                itemsValue: itemsDraft.map($ => ({
                    ...$, loadedProductMini: getLoadedItem($)
                })),
                paymentMethodsValue: draftState.paymentMethodsValue,
                stockRequestsValue: draftState.stockRequestsValue,
                purchaseSupplierInvoiceNoValue: draftState.purchaseSupplierInvoiceNoValue,
                purchaseSupplierInvoiceDateValue: draftState.purchaseSupplierInvoiceDateValue,
                salespersonIdValue: draftState.salespersonIdValue,
                returnFlowParentIdRequest: draftState.returnFlowParentIdRequest,
                printProductLabels: draftState.printProductLabels,
                labelType: draftState.labelType,
                printPerQuantity: draftState.printPerQuantity,
                displayPrice: draftState.displayPrice,
                displaySku: draftState.displaySku,
                labelAdvanced: draftState.labelAdvanced,
                labelSelectedTemplateId: draftState.labelSelectedTemplateId,

                sourceIdPickerKey: Util.newTempId(),
                destinationIdPickerKey: Util.newTempId(),
                itemsPickerKey: Util.newTempId(),
                paymentMethodPickerKey: Util.newTempId(),
                supplierDateKey: Util.newTempId(),
            }, stopLoading)
        } catch (e) {
            UIUtil.showError();
        } finally {
            stopLoading();
        }
    }

    resetToDefaults(extra) {
        this.setState({
            // sourceTypeValue: SOURCE_TYPE_SUPPLIER + "",
            // destinationTypeValue: DESTINATION_TYPE_STORE,
            sourceTypeValue: this.getDefaultSourceTypeValue(),//SOURCE_TYPE_SUPPLIER + "",
            destinationTypeValue: this.getDefaultDestinationTypeValue(),//DESTINATION_TYPE_STORE,

            sourceIdValue: 0,
            destinationIdValue: this.getDefaultDestinationIdValue(),
            returnFlowParentIdValue: 0,

            amountValue: 0,
            amountAdditionalCostsValue: 0,
            salesAmountDiscountValue: 0,
            salesAmountTaxValue: 0,
            isQuotationValue: false,

            purchaseSupplierInvoiceNoValue: "",
            purchaseSupplierInvoiceDateValue: null,
            salespersonIdValue: 0,

            itemsValue: [],
            paymentMethodsValue: [],
            stockRequestsValue: [],
            sourceIdPickerKey: Util.newTempId(),
            destinationIdPickerKey: Util.newTempId(),
            itemsPickerKey: Util.newTempId(),
            paymentMethodPickerKey: Util.newTempId(),
            supplierDateKey: Util.newTempId(),

            printProductLabels: false,
            labelType: "Barcode only",
            printPerQuantity: true,
            displayPrice: false,
            displaySku: false,
            labelAdvanced: false,
            labelSelectedTemplateId: undefined,


            ...(extra !== undefined ? extra : {})
        })
    }

    getItemCount() {
        return this.state.itemsValue?.length ?? 0;
    }

    getTotalQty() {
        const items = this.state.itemsValue ?? []
        return items.map($ => big($.quantityValue)).reduce((t, c) => t.add(c), big(0)).toNumber()
    }

    createFlow(force) {
        if (this._internal_state_creating_flow) {
            return;
        }

        if (this.state.sourceTypeValue === SOURCE_TYPE_STOCK_CREATION && !(this.state.sourceIdValue > 0)) {
            UIUtil.showInfo("Please select an inventory adjustment account");
            return;
        }

        if (this.state.destinationTypeValue === DESTINATION_TYPE_STOCK_TERMINATION && !(this.state.destinationIdValue > 0)) {
            UIUtil.showInfo("Please select an inventory adjustment account");
            return;
        }

        if (this.shouldHaveTransaction()) {
            if (this.isAmountNaN()) {
                UIUtil.showError("Amount modification is non-numeral");
                return;
            }
        }

        if (this.shouldHaveTransaction()) {
            if (this.state.paymentMethodsValue.length == 0 && !force) {
                this.setState({ showNoTransactionNoticeDialog: true, showCreateFlowConfirmDialog: false })
                return;
            } else if (force) {
                this.setState({ showNoTransactionNoticeDialog: false, showCreateFlowConfirmDialog: false })
            }
        }

        if (!force) {
            this.setState({ showNoTransactionNoticeDialog: false, showCreateFlowConfirmDialog: true });
            return;
        }

        this.setState({
            showNoTransactionNoticeDialog: false,
            showCreateFlowConfirmDialog: false,
        })

        const requestVenueSale = this.isSourceVenue() && this.state.destinationTypeValue == DESTINATION_TYPE_CUSTOMER;

        let flow = {
            sourceType: this.state.sourceTypeValue,
            sourceId: this.state.sourceIdValue,

            destinationType: this.state.destinationTypeValue,
            destinationId: requestVenueSale ? 0 : this.state.destinationIdValue,

            returnFlowParentId: this.state.returnFlowParentIdValue,

            items: [],
            stockRequests: this.state.stockRequestsValue,

            ...(this.shouldHaveTransaction() ? {
                transactionPaymentMethods: this.state.paymentMethodsValue,
                salesAmountSubtotal: this.state.amountValue,
                amount: this.getAmount(),
                amountAdditionalCosts: this.state.amountAdditionalCostsValue,
                salesAmountDiscount: this.state.salesAmountDiscountValue,
                salesAmountTax: this.state.salesAmountTaxValue,
            } : {}),

            quotation: this.state.isQuotationValue,
            quotationAmount: this.state.isQuotationValue ? this.state.amountValue : undefined,

            terminalId: this.props.posSalesReturnMode ? getTerminal().id : 0,

            requestVenueSale
        }

        if (this.props.posSalesReturnMode && this.shouldHaveTransaction()) {
            flow.salesAmountSubtotal = flow.amount / 1.05;
            flow.salesAmountTax = (flow.amount - flow.salesAmountSubtotal)
        }

        if (this.shouldHavePurchaseSupplierDetails()) {
            if (Util.isStringExists(this.state.purchaseSupplierInvoiceNoValue)) {
                flow.purchaseSupplierInvoiceNo = this.state.purchaseSupplierInvoiceNoValue;
            }

            if (Util.isNumberExist(this.state.purchaseSupplierInvoiceDateValue)) {
                flow.purchaseSupplierInvoiceDate = this.state.purchaseSupplierInvoiceDateValue;
            }
        }

        if (this.isStockTermination() || this.isStockCreation() || this.isTransfer() || this.isSales()) {
            if (Util.isNumberExist(this.state.purchaseSupplierInvoiceDateValue)) {
                flow.purchaseSupplierInvoiceDate = this.state.purchaseSupplierInvoiceDateValue;
            }
        }

        if (this.isSales()) {
            flow.salesPersonId = this.state.salespersonIdValue;
        }

        for (const item of this.state.itemsValue) {
            let mappedItem = {
                itemId: item.itemId,
                itemType: item.itemType,
                quantityValue: item.quantityValue,
                serialNumber: item.serialNumber,
                batchNo: item.batchNo
            };

            // if (flow.sourceType == SOURCE_TYPE_SUPPLIER) {
            //     if (isNaN(item.cost)) {
            //         UIUtil.showError("Invalid numeric value specified")
            //         return;
            //     }

            //     mappedItem.cost = item.cost;
            // }

            // if (flow.destinationType == DESTINATION_TYPE_SUPPLIER || flow.sourceType == SOURCE_TYPE_CUSTOMER) {
            //     if (isNaN(item.refund)) {
            //         UIUtil.showError("Invalid numeric value specified")
            //         return;
            //     }

            //     mappedItem.refund = item.refund;
            // }

            // if (flow.destinationType == DESTINATION_TYPE_CUSTOMER) {
            //     if (isNaN(item.price)) {
            //         UIUtil.showError("Invalid numeric value specified")
            //         return;
            //     }

            //     mappedItem.price = item.price;
            // }

            if (isNaN(item.amount)) {
                UIUtil.showError("Invalid numeric value specified")
                return;
            }

            mappedItem.amount = item.amount;

            flow.items.push(mappedItem);
        }

        this.setState({ creatingFlow: true });
        this._internal_state_creating_flow = true;
        Api.createStockFlow(flow, response => {
            this._internal_state_creating_flow = false;

            if (response.status === true) {
                if (this.state.printProductLabels) {
                    this.printLabels(response.payload);
                } else {
                    this.setState({ creatingFlow: false });
                    UIUtil.showSuccess();
                    if (this.props.posSalesReturnMode) {
                        this.props.history.goBack()
                        showGeneratedCouponsForRefund(response.payload)
                    } else {
                        this.props.history.replace("/stock-flow/" + response.payload)
                    }
                }
            } else {
                this.setState({ creatingFlow: false });
                UIUtil.showError(response.message)
            }
        })
    }

    printLabels(stockFlowId) {
        const onSuccess = () => {
            this.setState({ creatingFlow: false });
            UIUtil.showSuccess();
            this.props.history.replace("/stock-flow/" + stockFlowId)
        }

        const onError = (e) => {
            this.setState({ creatingFlow: false });
            UIUtil.showSuccess();
            UIUtil.showError("Failed to print labels");
            this.props.history.replace("/stock-flow/" + stockFlowId)
        }


        if (this.state.labelAdvanced) {
            let productsList = this.state.itemsValue.filter(item => item.itemType == OBJECT_TYPE_PRODUCT);
            getAndExportTemplate(this.state.labelSelectedTemplateId, productsList.map(item => ({ itemId: item.itemId, _templateCopyCount: this.state.printPerQuantity ? item.quantityValue : 1 })), { loadProducts: true })
                .then(onSuccess)
                .catch(onError)
        } else {
            let productsList = this.state.itemsValue.filter(item => item.itemType == OBJECT_TYPE_PRODUCT).map(item => item.itemId);
            Api.getProductBarcodes(productsList, productResponse => {
                if (productResponse.status === true) {
                    onSuccess();

                    if (this.state.printPerQuantity) {
                        for (const product of productResponse.payload) {
                            for (const item of this.state.itemsValue) {
                                if (product.id == item.itemId) {
                                    product.quantity = item.quantityValue;
                                    break;
                                }
                            }
                        }
                    }

                    printProductLabels(this.state.labelType, {
                        displayPrice: this.state.displayPrice,
                        displaySku: this.state.displaySku,
                        //printPerQuantity: true
                    }, productResponse.payload)
                        .then(() => { })
                        .catch(() => {
                            UIUtil.showError("Failed to print labels");
                        })
                } else {
                    this.setState({ creatingFlow: false });
                    UIUtil.showSuccess();
                    UIUtil.showError("Failed to print labels");
                    this.props.history.replace("/stock-flow/" + stockFlowId)
                }
            })
        }
    }

    onPageStart() {
        this.callPageApi(listener => Api.getStockFlowEndpointsList(listener), payload => {
            const templates = payload.templates.filter(item => item.type == TEMPLATE_TYPE_PRODUCT_LABEL || item.type == TEMPLATE_TYPE_LABEL_GENERIC);

            return {
                accountTree: payload.accountTree,
                suppliers: payload.suppliers,
                customers: payload.customers,
                warehouses: payload.warehouses,
                mobileUnits: payload.mobileUnits,
                stores: payload.stores,
                venues: payload.venues,
                salespeople: payload.salespeople,
                invBatchNos: payload.invBatchNos,
                taxRate: payload.taxRate,
                allowSalesReturnWithoutOriginalSales: payload.allowSalesReturnWithoutOriginalSales,
                requireSupplierInvoiceNoAndDate: payload.requireSupplierInvoiceNoAndDate,
                labelTemplates: templates,
                labelSelectedTemplateId: getDefaultTemplate(templates, TEMPLATE_TYPE_PRODUCT_LABEL, TEMPLATE_TYPE_LABEL_GENERIC).id
            }
        })
    }

    canCreate() {
        if (this.state.itemsValue.length == 0) {
            return false;
        }

        if (this.getSourceTypeList() !== undefined && !Util.isNumberExist(this.state.sourceIdValue)) {
            return false;
        }


        if (this.getDestinationTypeList() !== undefined && !Util.isNumberExist(this.state.destinationIdValue)) {
            return false;
        }

        if ((this.state.sourceTypeValue == SOURCE_TYPE_CUSTOMER || this.state.destinationTypeValue == DESTINATION_TYPE_SUPPLIER) && !Util.isNumberExist(this.state.returnFlowParentIdValue)) {
            if (!this.state.allowSalesReturnWithoutOriginalSales) {
                return false;
            }
        }

        for (const item of this.state.itemsValue) {
            if (!Util.isNumberExist(item.quantityValue)) {
                return false;
            }
        }

        if (!this.state.isQuotationValue) {
            let totalPaid = 0;
            for (const paymentMethod of this.state.paymentMethodsValue) {
                if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                    totalPaid += parseFloat(paymentMethod.amount);
                }
            }
        }

        return true;
    }

    isAmountNaN() {
        let amount = parseFloat(this.state.amountValue);
        amount += parseFloat(this.state.amountAdditionalCostsValue);
        amount -= parseFloat(this.state.salesAmountDiscountValue);
        amount += parseFloat(this.state.salesAmountTaxValue);
        return isNaN(amount);
    }

    getAmount(skipTax) {
        let amount = parseFloat(this.state.amountValue);
        amount += parseFloat(this.state.amountAdditionalCostsValue);
        amount -= parseFloat(this.state.salesAmountDiscountValue);
        if (!skipTax) {
            amount += parseFloat(this.state.salesAmountTaxValue);
        }
        return isNaN(amount) ? 0 : (amount > 0 ? amount : 0);
    }

    calculateAmount() {
        let totalAmount = 0;
        for (const item of this.state.itemsValue) {
            let amount = item.amount;

            // if (this.state.sourceTypeValue == SOURCE_TYPE_SUPPLIER) {
            //     amount = item.cost;
            // } else if (this.state.sourceTypeValue == SOURCE_TYPE_CUSTOMER) {
            //     amount = item.refund;
            // } else if (this.state.destinationTypeValue == DESTINATION_TYPE_SUPPLIER) {
            //     amount = item.refund;
            // } else if (this.state.destinationTypeValue == DESTINATION_TYPE_CUSTOMER) {
            //     amount = item.price;
            // }

            totalAmount += (amount * item.quantityValue);
        }
        return (isNaN(totalAmount) || totalAmount == "") ? 0 : parseFloat(totalAmount);
    }

    shouldHavePurchaseSupplierDetails() {
        return this.state.sourceTypeValue == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal();
    }

    hasFlowSpecificDetails() {
        return this.shouldHavePurchaseSupplierDetails() || this.isSales() || this.isStockTermination() || this.isStockCreation() || this.isTransfer()
    }

    shouldHaveTransaction() {
        if (this.state.destinationTypeValue == DESTINATION_TYPE_STOCK_TERMINATION || this.state.sourceTypeValue == SOURCE_TYPE_STOCK_CREATION) {
            return false;
        } else {
            return (this.isSourceExternal() && !this.isDestinationExternal()) || (!this.isSourceExternal() && this.isDestinationExternal());
        }

        // if (this.state.destinationTypeValue != DESTINATION_TYPE_STOCK_TERMINATION) {
        //     return (this.isSourceExternal() && !this.isDestinationExternal()) || (!this.isSourceExternal() && this.isDestinationExternal());
        // } else {
        //     return false;
        // }
    }

    canMakeQuotation() {
        //return !this.isSourceExternal() && this.state.destinationTypeValue == DESTINATION_TYPE_CUSTOMER;
        return false;
    }

    isSourceVenue() {
        return this.state.sourceTypeValue == SOURCE_TYPE_VENUE;
    }

    isSourceExternal() {
        return this.state.sourceTypeValue == SOURCE_TYPE_SUPPLIER || this.state.sourceTypeValue == SOURCE_TYPE_STOCK_CREATION || this.state.sourceTypeValue == SOURCE_TYPE_CUSTOMER;
    }

    isDestinationExternal() {
        return this.state.destinationTypeValue == DESTINATION_TYPE_SUPPLIER || this.state.destinationTypeValue == DESTINATION_TYPE_CUSTOMER || this.state.destinationTypeValue == DESTINATION_TYPE_STOCK_TERMINATION;
    }

    getDefaultSourceTypeValue() {
        if (this.props.creationOnly) {
            return SOURCE_TYPE_STOCK_CREATION;
        }

        if (isV2()) {
            return SOURCE_TYPE_STORE;
        }

        return this.props.posSalesReturnMode ? SOURCE_TYPE_CUSTOMER : (SOURCE_TYPE_SUPPLIER + "");
    }

    getDefaultDestinationIdValue() {
        return this.props.posSalesReturnMode ? getTerminal().storeId : 0;
    }

    getDefaultDestinationTypeValue() {
        if (this.props.terminationOnly) {
            return DESTINATION_TYPE_STOCK_TERMINATION;
        }

        if (isV2()) {
            return DESTINATION_TYPE_STORE;
        }

        return this.props.posSalesReturnMode ? DESTINATION_TYPE_STORE : DESTINATION_TYPE_STORE;
    }

    setSourceType(sourceType) {
        const clearFlowParentIdValue = sourceType != SOURCE_TYPE_CUSTOMER && this.state.destinationTypeValue != DESTINATION_TYPE_SUPPLIER;
        this.setState({
            sourceTypeValue: sourceType, sourceIdValue: 0, sourceIdPickerKey: Util.newTempId(),
            paymentMethodPickerKey: Util.newTempId(), paymentMethodsValue: [],
            ...(clearFlowParentIdValue ? { returnFlowParentIdValue: 0 } : {})
        }, () => {
            if (this.isSourceExternal() && this.isDestinationExternal()) {
                this.setState({ destinationTypeValue: DESTINATION_TYPE_STORE, destinationIdValue: 0, destinationIdPickerKey: Util.newTempId(), returnFlowParentIdValue: 0, paymentMethodPickerKey: Util.newTempId(), paymentMethodsValue: [] }, () => {
                    if (!this.canMakeQuotation()) {
                        this.setState({ isQuotationValue: false })
                    }
                })
            }
            if (!this.canMakeQuotation()) {
                this.setState({ isQuotationValue: false })
            }
        })
    }

    setDestinationType(destinationType) {
        const clearFlowParentIdValue = this.state.sourceTypeValue != SOURCE_TYPE_CUSTOMER && destinationType != DESTINATION_TYPE_SUPPLIER;
        this.setState({
            destinationTypeValue: destinationType, destinationIdValue: 0, destinationIdPickerKey: Util.newTempId(),
            paymentMethodPickerKey: Util.newTempId(), paymentMethodsValue: [],
            ...(clearFlowParentIdValue ? { returnFlowParentIdValue: 0 } : {})
        }, () => {
            if (!this.canMakeQuotation()) {
                this.setState({ isQuotationValue: false })
            }
        })
    }

    renderSalesReturnSalesSelector() {
        return (
            <div>
                <StockFlowField sales labelText=""
                    preventClick
                    onStockFlowChange={id => this.setState({ returnFlowParentIdValue: id })}
                    stockFlowId={this.state.returnFlowParentIdValue}
                    loadStockFlowRecordId={this.state.returnFlowParentIdRequest} />
                {Util.isNumberExist(this.state.returnFlowParentIdValue) && <>
                    <div style={{ height: '1rem' }} />
                    <SalePreview key={this.state.returnFlowParentIdValue + '-preview'} stockFlowId={this.state.returnFlowParentIdValue} onItemClick={item => {
                        this.stockItemEditorRef.current.addMultipleItems([{
                            id: Util.newTempId(), itemId: item.itemId, itemType: item.itemType, quantityValue: item.quantityValue,
                            //amount: Util.isNumberExist(item.discountedAmount) ? item.discountedAmount : item.amount
                            amount: item.discountedWithTax
                        }])
                    }} />

                </>}
            </div>
        )
    }

    renderSourceSelector() {
        if (this.props.creationOnly) {
            return <>
                <p>Stock Creation</p>
                <div style={{ height: '1rem' }} />
                <label className="bx--label">Inventory Adjustment Account</label>
                <div style={{ height: 40 }}>
                    <CustomComboBox
                        key={'-rpaboxstockcreation'}
                        items={this.state.accountTree}
                        selectedItem={this.state.sourceCreationAdjustmentAccountObject}
                        onSelectedItemUpdate={item => this.setState({ sourceIdValue: item !== undefined ? item.id : 0, sourceCreationAdjustmentAccountObject: item })}
                    />
                </div>
            </>
        }

        if (isV2()) {
            return (
                <div>
                    {!this.props.transferOnly && <TileGroup className={"horizontal-tile-radio " + ((hasCapabilitySupport("mobileUnit") || hasCapabilitySupport("thirdPartyPos")) ? "centered-content-tile" : "")} valueSelected={this.state.sourceTypeValue} onChange={value => this.setSourceType(value)}>
                        {/* <RadioTile value={SOURCE_TYPE_WAREHOUSE}>
                            <RadioItem icon={DataVis_232} title="Warehouse" desc="Transfer stock from warehouse" />
                        </RadioTile> */}
                        <RadioTile value={SOURCE_TYPE_STORE}>
                            <RadioItem icon={Store32} title="Location" desc="Transfer stock from location" />
                        </RadioTile>
                        <RadioTile value={SOURCE_TYPE_STOCK_CREATION}>
                            <RadioItem icon={Cube32} title="Create" desc="Create existing stock" />
                        </RadioTile>
                    </TileGroup>}

                    {this.getSourceTypeList() !== undefined && <>
                        <div style={{ height: '1rem' }} />
                        <ComboBox
                            key={this.state.sourceIdPickerKey}
                            //titleText={this.getSourceTypeName()}
                            titleText="Location"
                            items={this.getSourceTypeList()}
                            itemToString={item => item !== null ? item.value : ""}
                            selectedItem={this.getSourceTypeList().filter(item => item.id == this.state.sourceIdValue)[0]}
                            //initialSelectedItem={this.state[stateValue(field.property)]}
                            //onChange={e => this.setState({sourceIdValue: e.selectedItem !== null ? e.selectedItem.id : 0})} 
                            onChange={e => {
                                if (e.selectedItem === null) {
                                    this.setState({ sourceIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, sourceIdPickerKey: Util.newTempId(), })
                                } else {
                                    this.setState({ sourceIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                }
                            }}
                        />
                    </>}
                </div>
            )
        }

        return (
            <div>
                <TileGroup className={"horizontal-tile-radio " + ((hasCapabilitySupport("mobileUnit") || hasCapabilitySupport("thirdPartyPos")) ? "centered-content-tile" : "")} valueSelected={this.state.sourceTypeValue} onChange={value => this.setSourceType(value)}>
                    <RadioTile value={SOURCE_TYPE_SUPPLIER + ""}>
                        <RadioItem icon={Delivery32} title="Supplier" desc="Purchasing stock from supplier" />
                    </RadioTile>
                    <RadioTile value={SOURCE_TYPE_CUSTOMER}>
                        <RadioItem icon={AirlinePassengerCare32} title="Customer" desc="Receive stock from customer (sales return)" />
                    </RadioTile>
                    <RadioTile value={SOURCE_TYPE_WAREHOUSE}>
                        <RadioItem icon={DataVis_232} title="Warehouse" desc="Transfer stock from warehouse" />
                    </RadioTile>
                    <RadioTile value={SOURCE_TYPE_STORE}>
                        <RadioItem icon={Store32} title="Store" desc="Transfer stock from store" />
                    </RadioTile>
                    {hasCapabilitySupport("mobileUnit") &&
                        <RadioTile value={SOURCE_TYPE_MOBILE_UNIT}>
                            <RadioItem icon={Van32} title="Mobile unit" desc="Transfer stock from mobile unit" />
                        </RadioTile>}
                    {hasCapabilitySupport("thirdPartyPos") &&
                        <RadioTile value={SOURCE_TYPE_VENUE}>
                            <RadioItem icon={Building32} title="Venue" desc="Transfer stock from venue" />
                        </RadioTile>}
                    <RadioTile value={SOURCE_TYPE_STOCK_CREATION}>
                        <RadioItem icon={Cube32} title="Create" desc="Create existing stock" />
                    </RadioTile>
                </TileGroup>

                {this.state.sourceTypeValue == SOURCE_TYPE_CUSTOMER && <>
                    <div style={{ height: '1rem' }} />
                    <StockFlowField sales labelText="Original Sales"
                        onStockFlowChange={id => this.setState({ returnFlowParentIdValue: id })}
                        stockFlowId={this.state.returnFlowParentIdValue}
                        loadStockFlowRecordId={this.state.returnFlowParentIdRequest} />
                </>}

                {this.state.sourceTypeValue == SOURCE_TYPE_STOCK_CREATION && <>
                    <div style={{ height: '1rem' }} />
                    <label className="bx--label">Inventory Adjustment Account</label>
                    <div style={{ height: 40 }}>
                        <CustomComboBox
                            key={'-rpaboxstockcreation'}
                            items={this.state.accountTree}
                            selectedItem={this.state.sourceCreationAdjustmentAccountObject}
                            onSelectedItemUpdate={item => this.setState({ sourceIdValue: item !== undefined ? item.id : 0, sourceCreationAdjustmentAccountObject: item })}
                        />
                    </div>
                </>}

                {this.getSourceTypeList() !== undefined && <>
                    {!this.props.transferOnly && <div style={{ height: '1rem' }} />}
                    <ComboBox
                        key={this.state.sourceIdPickerKey}
                        titleText={this.getSourceTypeName()}
                        items={this.getSourceTypeList()}
                        itemToString={item => item !== null ? item.value : ""}
                        selectedItem={this.getSourceTypeList().filter(item => item.id == this.state.sourceIdValue)[0]}
                        //initialSelectedItem={this.state[stateValue(field.property)]}
                        //onChange={e => this.setState({sourceIdValue: e.selectedItem !== null ? e.selectedItem.id : 0})} 
                        onChange={e => {
                            if (e.selectedItem === null) {
                                this.setState({ sourceIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, sourceIdPickerKey: Util.newTempId(), })
                            } else {
                                this.setState({ sourceIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                            }
                        }}
                    />
                </>}
            </div>
        )
    }

    renderDestinationSelector() {
        if (this.props.terminationOnly) {
            return (<div>
                <p>Stock Termination</p>
                <div style={{ height: '1rem' }} />
                <label className="bx--label">Inventory Adjustment Account</label>
                <div style={{ height: 40 }}>
                    <CustomComboBox
                        key={'-rpaboxstock'}
                        //items={this.state.accountTree.filter(item => item.id == (LEDGER_TYPE_EXPENSE))[0].items}
                        items={this.state.accountTree}
                        selectedItem={this.state.destinationTerminationCostObject}
                        onSelectedItemUpdate={item => this.setState({ destinationIdValue: item !== undefined ? item.id : 0, destinationTerminationCostObject: item })}
                    />
                </div>
            </div>)
        }

        if (isV2()) {
            return (
                <div>
                    {!this.props.transferOnly && <TileGroup className={"horizontal-tile-radio " + ((hasCapabilitySupport("mobileUnit") || hasCapabilitySupport("thirdPartyPos")) ? "centered-content-tile" : "")} valueSelected={this.state.destinationTypeValue} onChange={value => this.setDestinationType(value)}>
                        <RadioTile value={DESTINATION_TYPE_STORE}>
                            <RadioItem icon={Store32} title="Location" desc="Transfer stock to location" />
                        </RadioTile>
                        <RadioTile value={DESTINATION_TYPE_STOCK_TERMINATION} disabled={this.isSourceExternal()}>
                            <RadioItem icon={CheckboxIndeterminate32} title="Terminate" desc="Terminate existing stock" />
                        </RadioTile>
                    </TileGroup>}

                    {this.state.destinationTypeValue == DESTINATION_TYPE_STOCK_TERMINATION && <>
                        {!this.props.transferOnly && <div style={{ height: '1rem' }} />}
                        <label className="bx--label">Inventory Adjustment Account</label>
                        <div style={{ height: 40 }}>
                            <CustomComboBox
                                key={'-rpaboxstock'}
                                //items={this.state.accountTree.filter(item => item.id == (LEDGER_TYPE_EXPENSE))[0].items}
                                items={this.state.accountTree}
                                selectedItem={this.state.destinationTerminationCostObject}
                                onSelectedItemUpdate={item => this.setState({ destinationIdValue: item !== undefined ? item.id : 0, destinationTerminationCostObject: item })}
                            />
                        </div>
                    </>}

                    {this.getDestinationTypeList() !== undefined && <>
                        <div style={{ height: '1rem' }} />
                        <ComboBox
                            key={this.state.destinationIdPickerKey}
                            // titleText={this.getDestinationTypeName()}
                            titleText="Location"
                            items={this.getDestinationTypeList()}
                            itemToString={item => item !== null ? item.value : ""}
                            selectedItem={this.getDestinationTypeList().filter(item => item.id == this.state.destinationIdValue)[0]}
                            //initialSelectedItem={this.state[stateValue(field.property)]}

                            onChange={e => {
                                if (e.selectedItem === null) {
                                    this.setState({ destinationIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, destinationIdPickerKey: Util.newTempId(), })
                                } else {
                                    this.setState({ destinationIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                                }
                            }} />
                    </>}
                </div>
            )
        }

        return (
            <div>
                <TileGroup className={"horizontal-tile-radio " + ((hasCapabilitySupport("mobileUnit") || hasCapabilitySupport("thirdPartyPos")) ? "centered-content-tile" : "")} valueSelected={this.state.destinationTypeValue} onChange={value => this.setDestinationType(value)}>

                    <RadioTile value={DESTINATION_TYPE_SUPPLIER + ""} disabled={this.isSourceExternal() || this.isSourceVenue()}>
                        <RadioItem icon={Delivery32} title="Supplier" desc="Return stock to supplier (purchase return)" />
                    </RadioTile>
                    <RadioTile value={DESTINATION_TYPE_CUSTOMER - (this.isSourceVenue() ? 100 : 0)} disabled={this.isSourceExternal() || this.isSourceVenue()}>
                        <RadioItem icon={AirlinePassengerCare32} title="Customer" desc="Sell to customer" />
                    </RadioTile>
                    <RadioTile value={DESTINATION_TYPE_WAREHOUSE}>
                        <RadioItem icon={DataVis_232} title="Warehouse" desc="Transfer stock to warehouse" />
                    </RadioTile>
                    <RadioTile value={DESTINATION_TYPE_STORE}>
                        <RadioItem icon={Store32} title="Store" desc="Transfer stock to store" />
                    </RadioTile>
                    {hasCapabilitySupport("mobileUnit") &&
                        <RadioTile value={DESTINATION_TYPE_MOBILE_UNIT}>
                            <RadioItem icon={Van32} title="Mobile Unit" desc="Transfer stock to mobile unit" />
                        </RadioTile>}
                    {hasCapabilitySupport("thirdPartyPos") &&
                        <RadioTile value={DESTINATION_TYPE_VENUE}>
                            <RadioItem icon={Building32} title="Venue" desc="Transfer stock to venue" />
                        </RadioTile>}
                    {/* <RadioTile value={DESTINATION_TYPE_STOCK_TERMINATION} disabled={this.isSourceExternal() || this.isSourceVenue()}> */}
                    <RadioTile value={DESTINATION_TYPE_STOCK_TERMINATION} disabled={this.isSourceExternal()}>
                        <RadioItem icon={CheckboxIndeterminate32} title="Terminate" desc="Terminate existing stock" />
                    </RadioTile>

                    {this.isSourceVenue() &&
                        <RadioTile value={DESTINATION_TYPE_CUSTOMER}>
                            <RadioItem icon={BuildingInsights_132} title="Venue Customer" desc="Create venue sales" />
                        </RadioTile>}

                </TileGroup>

                {this.state.destinationTypeValue == DESTINATION_TYPE_SUPPLIER && <>
                    <div style={{ height: '1rem' }} />
                    <StockFlowField purchase labelText="Original Purchase"
                        onStockFlowChange={id => this.setState({ returnFlowParentIdValue: id })}
                        stockFlowId={this.state.returnFlowParentIdValue}
                        loadStockFlowRecordId={this.state.returnFlowParentIdRequest} />
                </>}

                {this.state.destinationTypeValue == DESTINATION_TYPE_STOCK_TERMINATION && <>
                    <div style={{ height: '1rem' }} />
                    <label className="bx--label">Inventory Adjustment Account</label>
                    <div style={{ height: 40 }}>
                        <CustomComboBox
                            key={'-rpaboxstock'}
                            //items={this.state.accountTree.filter(item => item.id == (LEDGER_TYPE_EXPENSE))[0].items}
                            items={this.state.accountTree}
                            selectedItem={this.state.destinationTerminationCostObject}
                            onSelectedItemUpdate={item => this.setState({ destinationIdValue: item !== undefined ? item.id : 0, destinationTerminationCostObject: item })}
                        />
                    </div>
                </>}

                {this.getDestinationTypeList() !== undefined && this.state.destinationTypeValue != DESTINATION_TYPE_CUSTOMER && <>
                    <div style={{ height: '1rem' }} />
                    <ComboBox
                        key={this.state.destinationIdPickerKey}
                        titleText={this.getDestinationTypeName()}
                        items={this.getDestinationTypeList()}
                        itemToString={item => item !== null ? item.value : ""}
                        selectedItem={this.getDestinationTypeList().filter(item => item.id == this.state.destinationIdValue)[0]}
                        //initialSelectedItem={this.state[stateValue(field.property)]}

                        onChange={e => {
                            if (e.selectedItem === null) {
                                this.setState({ destinationIdValue: e.selectedItem !== null ? e.selectedItem.id : 0, destinationIdPickerKey: Util.newTempId(), })
                            } else {
                                this.setState({ destinationIdValue: e.selectedItem !== null ? e.selectedItem.id : 0 })
                            }
                        }} />
                </>}

                {this.state.destinationTypeValue == DESTINATION_TYPE_CUSTOMER && <>
                    <div style={{ height: '1rem' }} />
                    <CustomDialogSelectField value={this.state.destinationIdValue} onChange={destinationIdValue => this.setState({ destinationIdValue })} />
                </>}
            </div>
        )
    }

    renderQuotation() {
        return (
            <h2>{'AED'} {this.getAmount().toFixed(2)}</h2>
        )
    }

    renderTransactionsSection() {
        return <TransactionEditor key={this.state.paymentMethodPickerKey}
            onUpdate={paymentMethods => this.setState({ paymentMethodsValue: paymentMethods })}

            currency={"AED"}
            incoming={!this.isSourceExternal()}
            totalAmount={this.getAmount()}

            defaultPaymentMethods={this.state.selectedDraft !== undefined ? this.state.paymentMethodsValue : undefined}

            hasCouponMethod={this.props.posSalesReturnMode}
        />
    }

    isSales() {
        return (StockFlowUtil.isSales({ destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue }));
    }

    isStockCreation() {
        return StockFlowUtil.isCreation({ destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue });
    }

    isStockTermination() {
        return StockFlowUtil.isTermination({ destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue });
    }

    isTransfer() {
        const sourceExternal = StockFlowUtil.isSourceExternal({ destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue });
        const destinationExternal = StockFlowUtil.isDestinationExternal({ destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue });

        return !sourceExternal && !destinationExternal;
    }

    renderItemsSection() {
        return (<>
            {(StockFlowUtil.isSales({ destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue })) &&
                <label className="bx--label" style={{ marginBottom: '0.5rem', fontSize: 12 }}>Item prices should NOT include tax</label>}

            {!this.props.posSalesReturnMode && (StockFlowUtil.isSalesReturn({ destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue })) &&
                <label className="bx--label" style={{ marginBottom: '0.5rem', fontSize: 12 }}>Item refunds should NOT include tax</label>}

            {(StockFlowUtil.isPurchase({ destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue })) &&
                <label className="bx--label" style={{ marginBottom: '0.5rem', fontSize: 12 }}>Item costs should NOT include tax</label>}

            {(StockFlowUtil.isCreation({ destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue })) &&
                <label className="bx--label" style={{ marginBottom: '0.5rem', fontSize: 12 }}>Item costs will be used exactly as inputted in inventory calculations</label>}

            <div style={{ position: 'relative' }}>
                <div style={
                    this.props.posSalesReturnMode
                        ? {}
                        : { marginLeft: 'calc(-12.5vw + 1rem)', width: 'calc(100vw - 2rem)', top: 0 }
                }>
                    <StockItemEditor
                        multipleInputEditorRef={this.stockItemEditorRef}
                        key={this.state.itemsPickerKey} defaultItems={this.state.itemsValue}
                        //REPEATED
                        stockItemMode={{
                            destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue,
                            hideLabelBtn: this.props.posSalesReturnMode,
                            stockFlow: { destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue },
                            sourceId: this.state.sourceIdValue,
                            invBatchNos: this.state.invBatchNos, setInvBatchNos: invBatchNos => this.setState({ invBatchNos })
                        }}
                        onItemsUpdated={items => this.setState({ itemsValue: items }, () => {
                            const amount = this.calculateAmount();
                            this.setState({ amountValue: amount })
                        })}
                    />
                </div>
            </div>
        </>)
    }

    loadStockItemEditorItems(items) {
        this.setState({
            itemsPickerKey: Util.newTempId(),
            itemsValue: items
        })
    }

    addProductToStockItemEditor(product) {
        this.setState(prevState => ({
            itemsPickerKey: Util.newTempId(),
            itemsValue: [
                ...prevState.itemsValue,
                { id: Util.newTempId(), itemId: product.id, itemType: OBJECT_TYPE_PRODUCT }
            ]
        }))
    }

    newAddProductToStockItemEditor(product) {
        this.stockItemEditorRef.current.addItem({
            id: Util.newTempId(), itemId: product.id, itemType: OBJECT_TYPE_PRODUCT
        })
    }

    addMultipleProductToStockItemEditor(products) {
        this.stockItemEditorRef.current.addMultipleItems(products.map(product => ({
            id: Util.newTempId(), itemId: product.id, itemType: product.type
        })))
    }

    renderAmount() {
        return (
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <p>AED</p>
                <NumberInput hideSteppers min={0} invalidText="Invalid numeric value" value={this.state.amountValue} onChange={e => {
                    this.setState({ amountValue: (e.target.value < 0 || e.target.value == "") ? 0 : parseFloat(e.target.value) })
                }} />
            </div>
        )
    }

    renderSaleDetails() {
        return (<>
            <ComboBoxField title={"Salesperson"} placeholder={"Optional"} options={this.state.salespeople} value={this.state.salespersonIdValue} setValue={val => this.setState({ salespersonIdValue: val })} />
            <div style={{ marginTop: '1rem' }} />
            {this.renderCustomDateDetails()}
        </>)
    }

    renderStockTransferSelection() {

        return (
            <>
                <div style={{ marginBottom: '1rem' }}>
                    <AssignedStockRequests editable
                        value={this.state.stockRequestsValue}
                        setValue={values => this.setState(prev => ({ stockRequestsValue: typeof values === 'function' ? values(prev.stockRequestsValue) : values }))}
                    />
                </div>
            </>
        )
    }

    renderCustomDateDetails() {
        const haveDate = this.isStockTermination() || this.isTransfer() || this.isStockCreation();

        if (!haveDate) {
            // return null;
        }

        let purchaseSupplierInvoiceDateValue = this.state.purchaseSupplierInvoiceDateValue;
        if (!Util.isNumberExist(purchaseSupplierInvoiceDateValue)) {
            purchaseSupplierInvoiceDateValue = undefined;
        }

        return (
            <>
                <div style={{ marginBottom: '1rem' }}>
                    {/* <label className="bx--label">{labelText}</label> */}
                    <DatePicker datePickerType="single"
                        dateFormat="d/m/Y"
                        key={this.state.supplierDateKey}
                        value={purchaseSupplierInvoiceDateValue}
                        onChange={e => {
                            if (e.length > 0) {
                                this.setState({
                                    purchaseSupplierInvoiceDateValue: e[0].getTime(),
                                })
                            } else {
                                this.setState({
                                    purchaseSupplierInvoiceDateValue: undefined
                                })
                            }
                        }}
                    >
                        <DatePickerInput
                            // placeholder="mm/dd/yyyy"
                            placeholder="dd/mm/yyyy"
                            labelText={"Date (optional)"}
                        />
                    </DatePicker>
                </div>
            </>
        )
    }

    renderSupplierPurchaseDetails() {
        let purchaseSupplierInvoiceDateValue = this.state.purchaseSupplierInvoiceDateValue;
        if (!Util.isNumberExist(purchaseSupplierInvoiceDateValue)) {
            purchaseSupplierInvoiceDateValue = undefined;
        }

        return (
            <>
                <div style={{ marginBottom: '1rem' }}>
                    <TextInput
                        labelText={this.state.requireSupplierInvoiceNoAndDate ? "Supplier Invoice No." : "Supplier Invoice No. (optional)"}
                        value={this.state.purchaseSupplierInvoiceNoValue}
                        onChange={e => this.setState({ purchaseSupplierInvoiceNoValue: e.target.value })}
                    />
                </div>

                <div style={{ marginBottom: '1rem' }}>
                    {/* <label className="bx--label">{labelText}</label> */}
                    <DatePicker datePickerType="single"
                        dateFormat="d/m/Y"
                        key={this.state.supplierDateKey}
                        value={purchaseSupplierInvoiceDateValue}
                        onChange={e => {
                            if (e.length > 0) {
                                this.setState({
                                    purchaseSupplierInvoiceDateValue: e[0].getTime(),
                                })
                            } else {
                                this.setState({
                                    purchaseSupplierInvoiceDateValue: undefined
                                })
                            }
                        }}
                    >
                        <DatePickerInput
                            // placeholder="mm/dd/yyyy"
                            placeholder="dd/mm/yyyy"
                            labelText={this.state.requireSupplierInvoiceNoAndDate ? "Supplier Invoice Date" : "Supplier Invoice Date (optional)"}
                        />
                    </DatePicker>
                </div>
            </>
        )
    }

    renderAmountModify() {
        return (
            <>
                <div style={{ marginBottom: '1rem' }}>
                    <label className="bx--label">{'Addition (+)'}</label>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                        <p>AED</p>
                        <NumberInput size="lg" hideSteppers min={0} invalidText="Invalid numeric value" value={this.state.amountAdditionalCostsValue} onChange={e => this.setState({ amountAdditionalCostsValue: e.target.value < 0 ? 0 : e.target.value })} />
                    </div>
                </div>

                <div style={{ marginBottom: '0rem' }}>
                    <label className="bx--label">{'Deduction (-)'}</label>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                        <p>AED</p>
                        <NumberInput size="lg" hideSteppers min={0} invalidText="Invalid numeric value" value={this.state.salesAmountDiscountValue} onChange={e => this.setState({ salesAmountDiscountValue: e.target.value < 0 ? 0 : e.target.value })} />
                    </div>
                </div>
            </>
        )
    }

    renderFinances() {
        return (
            <>
                <div style={{ marginBottom: '0rem' }}>
                    <label className="bx--label">{'Discount (-)'}</label>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                        <p>AED</p>
                        <NumberInput size="lg" hideSteppers min={0} invalidText="Invalid numeric value" value={this.state.salesAmountDiscountValue} onChange={e => this.setState({ salesAmountDiscountValue: e.target.value < 0 ? 0 : e.target.value })} />
                    </div>
                </div>
            </>
        )
    }

    renderTax() {
        return (
            <>
                <div style={{ marginBottom: '1rem' }}>
                    <TaxInput
                        amount={this.getAmount(true)}
                        taxValue={this.state.salesAmountTaxValue} onTaxValueChange={salesAmountTaxValue => this.setState({ salesAmountTaxValue })} />
                    {/* <label className="bx--label">{'Tax (+)'}</label>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: '1rem' }}>
                        <p>AED</p>
                        <NumberInput size="lg" hideSteppers min={0} invalidText="Invalid numeric value" value={this.state.salesAmountTaxValue} onChange={e => this.setState({ salesAmountTaxValue: e.target.value < 0 ? 0 : e.target.value })} />
                        <Button onClick={() => {
                            let taxAmount = (parseFloat(this.state.taxRate) * this.getAmount(true)).toFixed(2);
                            this.setState({ salesAmountTaxValue: taxAmount });
                        }} renderIcon={Percentage16} kind="secondary">Calculate ({this.state.taxRate * 100}%)</Button>
                    </div> */}
                </div>
            </>
        )
    }

    renderPosSalesReturn() {
        const componentMode = this.props.componentMode;
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: componentMode ? '3rem' : '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: componentMode ? '90vw' : '75vw' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <div style={{ flex: 1 }}>
                            <h1>Sales Return</h1>
                            <p style={{ fontSize: 18 }}>Initiating</p>
                        </div>
                    </div>

                    <Section icon={Number_132} title={"Select original sales" + (this.state.allowSalesReturnWithoutOriginalSales ? " (optional)" : "")}>
                        {this.renderSalesReturnSalesSelector()}
                    </Section>

                    <Section icon={Number_232} title="Select products" rightComponent={<Tag>VAT included in refunds</Tag>}>
                        {this.renderItemsSection()}
                    </Section>

                    <Section icon={Number_332} title={"Create refund transaction"} >
                        {this.renderTransactionsSection()}
                    </Section>

                    <Section icon={Number_432} title="Confirm" extraTopMargin>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ButtonSet style={{ width: 392 }}>
                                <Button onClick={() => this.resetToDefaults()} disabled={this.state.creatingFlow} kind="ghost" renderIcon={Reset16}>Reset to Defaults</Button>
                                <Button onClick={() => this.createFlow(false)} disabled={!this.canCreate()} loading={this.state.creatingFlow} renderIcon={FlowStream16}>Create Sales Return</Button>
                            </ButtonSet>
                        </div>
                    </Section>
                </div>

                <ComposedModal
                    open={this.state.showNoTransactionNoticeDialog}
                    onClose={() => this.setState({ showNoTransactionNoticeDialog: false })}
                    size="sm"
                //style={{background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden'}}
                >
                    <ModalHeader label="Confirm" title="Are you sure?" />
                    <ModalBody>
                        <p>Are you sure you want to create and finalize this sales return?</p>
                        <p>IMPORTANT NOTE: No transactions have been created/specified for this sales return</p>
                    </ModalBody>
                    <div style={{ height: 15 }} />
                    <ModalFooter>
                        <ButtonSet style={{ width: '100%' }}>
                            <Button kind="secondary" onClick={() => this.setState({ showNoTransactionNoticeDialog: false })}>
                                Cancel
                            </Button>
                            <Button onClick={() => {
                                this.createFlow(true)
                            }}>
                                Confirm
                            </Button>
                        </ButtonSet>
                    </ModalFooter>
                </ComposedModal>

                <ComposedModal
                    open={this.state.showCreateFlowConfirmDialog}
                    onClose={() => this.setState({ showCreateFlowConfirmDialog: false })}
                    size="sm"
                //style={{background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden'}}
                >
                    <ModalHeader label="Confirm" title="Are you sure?" />
                    <ModalBody>
                        <p>Are you sure you want to create and finalize this sales return?</p>
                    </ModalBody>
                    <div style={{ height: 15 }} />
                    <ModalFooter>
                        <ButtonSet style={{ width: '100%' }}>
                            <Button kind="secondary" onClick={() => this.setState({ showCreateFlowConfirmDialog: false })}>
                                Cancel
                            </Button>
                            <Button onClick={() => {
                                this.createFlow(true)
                            }}>
                                Confirm
                            </Button>
                        </ButtonSet>
                    </ModalFooter>
                </ComposedModal>

                <div className="full-size-dialog">
                    <ItemCreatorDialog
                        fullSized
                        //filterProperties={["name", "email", "phoneNumber"]}
                        objectType={OBJECT_TYPE_PRODUCT}
                        open={this.state.showCreateProductDialog} onClose={() => this.setState({ showCreateProductDialog: false })}
                        onCreatedItem={createdItem => {
                            UIUtil.showSuccess();
                            this.setState({ showCreateProductDialog: false })
                            this.addProductToStockItemEditor(createdItem);
                        }} />
                </div>

                <ComposedModal
                    key="save-state-dialog"
                    open={this.state.showSaveDraftDialog}
                    onClose={() => this.setState({ showSaveDraftDialog: false })}
                    size="sm">
                    <ModalHeader label="Saving" title="Draft" />
                    <ModalBody style={{ paddingRight: '1rem' }}>
                        <TextInput
                            data-modal-primary-focus
                            value={this.state.saveDraftTitleValue}
                            onChange={e => this.setState({ saveDraftTitleValue: e.target.value })}
                            labelText="Title"
                            placeholder="Optional title"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <ButtonSet style={{ width: '100%' }}>
                            <Button kind="secondary" onClick={() => this.setState({ showSaveDraftDialog: false })} renderIcon={ErrorFilled16}>
                                Cancel
                            </Button>
                            <Button onClick={() => this.saveUpdateDraft()} renderIcon={Save16} loading={this.state.savingDraft}>
                                Save
                            </Button>
                        </ButtonSet>
                    </ModalFooter>
                </ComposedModal>
                <MyStateDraftsDialog open={this.state.showDrafts} onClose={() => this.setState({ showDrafts: false })} stateType={STATE_TYPE_STOCK_FLOW_CREATOR}
                    onLoadDraftRequest={draft => this.loadDraft(draft)}
                />
            </div>
        )
    }

    getLayout() {
        const componentMode = this.props.componentMode;
        const posSalesReturnMode = this.props.posSalesReturnMode;

        if (posSalesReturnMode) {
            return this.renderPosSalesReturn();
        }

        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: componentMode ? '3rem' : '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: componentMode ? '90vw' : '75vw' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <div style={{ flex: 1 }}>
                            <h1>{posSalesReturnMode ? 'Sales Return' : 'Stock Flow'}</h1>
                            <p style={{ fontSize: 18 }}>Initiating</p>
                        </div>
                        {!posSalesReturnMode && <>
                            {this.state.selectedDraft ? (
                                <div style={{
                                    background: '#f4f4f4', borderRadius: 5, paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem', paddingBottom: '1rem', minWidth: 250,
                                    display: 'flex',
                                }}>
                                    <div style={{ flex: 1 }}>
                                        <p style={{ fontSize: 12, opacity: 0.65 }}>Selected Draft</p>
                                        <h4>{this.state.selectedDraft.title}</h4>
                                    </div>
                                    <Button onClick={() => this.closeSelectedDraft()} kind="ghost" hasIconOnly renderIcon={Close16} iconDescription="Close draft" />
                                </div>
                            ) : (
                                <Button onClick={() => this.setState({ showDrafts: true })} renderIcon={LicenseDraft16} kind="secondary">Load from draft</Button>
                            )}
                        </>}
                    </div>

                    <Section icon={Number_132} title={posSalesReturnMode ? "Select original sales" : "Select source"}>
                        {posSalesReturnMode ? this.renderSalesReturnSalesSelector() : this.renderSourceSelector()}
                    </Section>

                    <Section icon={Number_232} title="Select destination" extraTopMargin>
                        {this.renderDestinationSelector()}
                    </Section>

                    {this.shouldHavePurchaseSupplierDetails() &&
                        <Section icon={Number_332} title="Supplier Purchase Details" extraTopMargin>
                            {this.renderSupplierPurchaseDetails()}
                            {this.renderStockTransferSelection()}
                        </Section>}
                    {this.isSales() &&
                        <Section icon={Number_332} title="Sale Details" extraTopMargin>
                            {this.renderSaleDetails()}
                        </Section>}

                    {(this.isTransfer() || this.isStockCreation() || this.isStockTermination()) && <>
                        <Section icon={Number_332} title="Details" extraTopMargin>
                            {this.renderCustomDateDetails()}

                            {this.isTransfer() && <>
                                {this.renderStockTransferSelection()}
                            </>}
                        </Section>
                    </>}


                    <Section icon={this.hasFlowSpecificDetails() ? Number_432 : Number_332} title="Select products" extraTopMargin
                        //hasButton linkText="Create new product" onLinkClick={() => this.setState({showCreateProductDialog: true})}
                        rightComponent={
                            <>
                                <Button kind="secondary" renderIcon={ListChecked16} onClick={() => {
                                    UIUtil.showOverlay2(onClose => <ProductFinder
                                        bulkSelect loadProductsAndRest
                                        onBulkSelect={products => this.addMultipleProductToStockItemEditor(products)}
                                        onClose={onClose} />)
                                }}>Add multiple products</Button>
                                <div style={{ width: '0.5rem' }} />
                                <Button renderIcon={Product16} onClick={() => this.setState({ showCreateProductDialog: true })}>Create new product</Button>
                                <div style={{ width: '0.5rem' }} />
                                <Button className="green-button" renderIcon={Xls16} onClick={() => this.setState({ showImportItemsDialog: true })}>Import Excel</Button>
                            </>
                        }
                    >
                        {this.renderItemsSection()}
                    </Section>

                    {/* {this.shouldHaveTransaction() && 
                    <Section icon={this.hasFlowSpecificDetails() ? Number_532 : Number_432} title="Amount" extraTopMargin>
                        {this.renderAmount()}
                    </Section>} */}

                    {/* {this.shouldHaveTransaction() &&
                        <Section icon={this.hasFlowSpecificDetails() ? Number_532 : Number_432} title="Inventory Value Manipulation" extraTopMargin>
                            {isSqlAvgEngine() ? <span style={{ opacity: 0.65 }}>Not Applicable</span> : this.renderAmountModify()}
                        </Section>} */}
                    {this.shouldHaveTransaction() &&
                        <Section icon={this.hasFlowSpecificDetails() ? Number_532 : Number_432} title="Finances" extraTopMargin>
                            {!this.isSales() ? <span style={{ opacity: 0.65 }}>Not Applicable</span> : this.renderFinances()}
                        </Section>}

                    {this.shouldHaveTransaction() &&
                        <Section icon={this.hasFlowSpecificDetails() ? Number_632 : Number_532} title="Amount Tax" extraTopMargin>
                            {this.renderTax()}
                        </Section>}

                    {this.shouldHaveTransaction() &&
                        <Section icon={this.hasFlowSpecificDetails() ? Number_732 : Number_632} title={this.state.isQuotationValue ? "Make quotation" : "Create transaction"} extraTopMargin hasLink={this.canMakeQuotation()} linkText={this.state.isQuotationValue ? "Create transaction" : "Make a quotation"}
                            onLinkClick={() => this.setState(prevState => ({ isQuotationValue: !prevState.isQuotationValue, paymentMethodsValue: [] }))}>
                            {this.state.isQuotationValue ? this.renderQuotation() : this.renderTransactionsSection()}
                        </Section>}



                    <Section icon={this.shouldHaveTransaction() ? (this.hasFlowSpecificDetails() ? Number_832 : Number_732) : (this.hasFlowSpecificDetails() ? Number_532 : Number_432)} title="Confirm" extraTopMargin>
                        <div style={{ width: '100%', marginBottom: '1rem', padding: '1rem', borderRadius: 7, background: '#f4f4f4', border: '1px solid #00000020' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                <h5 style={{ flex: 1 }}>Stock Info</h5>
                                <Cube32 />
                                <div>
                                    <h6>Total Items</h6>
                                    <p style={{ fontSize: 16, fontWeight: 'bold', color: 'darkcyan' }}>{this.getItemCount()}</p>
                                </div>
                                <div style={{ width: '1rem' }} />
                                <Cube32 />
                                <div>
                                    <h6>Total Quantity</h6>
                                    <p style={{ fontSize: 16, fontWeight: 'bold', color: 'darkslateblue' }}>{this.getTotalQty()}</p>
                                </div>
                            </div>
                        </div>

                        {this.state.sourceTypeValue == SOURCE_TYPE_SUPPLIER &&
                            <div onClick={() => this.setState(prevState => ({ printProductLabels: !prevState.printProductLabels, labelType: "Barcode only", printPerQuantity: true }))} style={{ width: 200 }}>
                                <Checkbox labelText="Print product labels" checked={this.state.printProductLabels} />
                            </div>}

                        {this.state.printProductLabels && this.state.sourceTypeValue == SOURCE_TYPE_SUPPLIER &&
                            <div style={{ padding: '1rem', background: '#f4f4f4', animation: 'slide-in-anim 250ms', marginTop: '1rem', marginBottom: '1rem' }}>
                                <h4>Printing labels</h4>
                                <ContentSwitcher style={{ marginBottom: '1rem', marginTop: '0.5rem' }}
                                    selectedIndex={this.state.labelAdvanced ? 1 : 0} onChange={({ index }) => {
                                        this.setState({ labelAdvanced: index == 1 })
                                    }}>
                                    <Switch text="Basic" />
                                    <Switch text="Advanced" />
                                </ContentSwitcher>

                                {this.state.labelAdvanced && this.state.labelTemplates.filter(item => this.state.labelSelectedTemplateId == item.id)[0] ? (
                                    <ComboBox
                                        titleText="Template"
                                        light
                                        items={this.state.labelTemplates}
                                        value={this.state.labelTemplates.filter(item => this.state.labelSelectedTemplateId == item.id)[0].title}
                                        itemToString={item => item ? item.title : ''}
                                        onChange={e => this.setState({ labelSelectedTemplateId: e.selectedItem ? e.selectedItem.id : getDefaultTemplate(this.state.labelTemplates, TEMPLATE_TYPE_PRODUCT_LABEL, TEMPLATE_TYPE_LABEL_GENERIC).id })}
                                    // selectedItem={this.state.labelTemplates.filter(item => this.state.labelSelectedTemplateId == item.id)[0]}
                                    // itemToString={item => item ? item.title : ''}
                                    // onChange={e => this.setState({labelSelectedTemplateId: e.selectedItem ? e.selectedItem.id : undefined})}
                                    />
                                ) : (<>
                                    <label className="bx--label">Label type</label>
                                    <ComboBox
                                        light
                                        title="Label type"
                                        items={["Barcode only", "QR Code only", "Barcode and QR Code"]}
                                        value={this.state.labelType}
                                        onChange={e => this.setState({ labelType: e.selectedItem })}
                                    />
                                </>)}



                                <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <div onClick={() => this.setState(prevState => ({ printPerQuantity: !prevState.printPerQuantity }))}>
                                        <Checkbox labelText="Print per quantity" checked={this.state.printPerQuantity} />
                                    </div>
                                    {!this.state.labelAdvanced && <>
                                        <div onClick={() => this.setState(prevState => ({ displayPrice: !prevState.displayPrice }))}>
                                            <Checkbox labelText="Display price" checked={this.state.displayPrice} />
                                        </div>
                                        <div onClick={() => this.setState(prevState => ({ displaySku: !prevState.displaySku }))}>
                                            <Checkbox labelText="Display reference number" checked={this.state.displaySku} />
                                        </div>
                                    </>}
                                </div>
                            </div>}

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {/* <Button kind="ghost" onClick={() => {
                                if (this.state.selectedDraft) {
                                    this.saveUpdateDraft();
                                } else {
                                    this.setState({showSaveDraftDialog: true, saveDraftTitleValue: ""})
                                }
                            }} loading={this.state.savingDraft} disabled={this.state.creatingFlow} renderIcon={Save16}>{this.state.selectedDraft ? 'Update Draft' : 'Save Draft'}</Button>
                            <ButtonSet style={{width: 392}}>
                                <Button onClick={() => this.resetToDefaults()} disabled={this.state.creatingFlow} kind="secondary" renderIcon={Reset16}>Reset to Defaults</Button>
                                <Button onClick={() => this.createFlow(false)} disabled={!this.canCreate()} loading={this.state.creatingFlow} renderIcon={FlowStream16}>Create Flow</Button>
                            </ButtonSet> */}


                            <Button onClick={() => this.resetToDefaults()} disabled={this.state.creatingFlow} kind="ghost" renderIcon={Reset16}>Reset to Defaults</Button>
                            <ButtonSet style={{ width: 392 }}>
                                <Button kind="secondary" onClick={() => {
                                    if (this.state.selectedDraft) {
                                        this.saveUpdateDraft();
                                    } else {
                                        this.setState({ showSaveDraftDialog: true, saveDraftTitleValue: "" })
                                    }
                                }} loading={this.state.savingDraft} disabled={this.state.creatingFlow} renderIcon={Save16}>{this.state.selectedDraft ? 'Update Draft' : 'Save Draft'}</Button>
                                <Button onClick={() => this.createFlow(false)} disabled={!this.canCreate()} loading={this.state.creatingFlow} renderIcon={FlowStream16}>Create Flow</Button>
                            </ButtonSet>
                        </div>
                    </Section>
                </div>

                <ComposedModal
                    open={this.state.showNoTransactionNoticeDialog}
                    onClose={() => this.setState({ showNoTransactionNoticeDialog: false })}
                    size="sm"
                //style={{background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden'}}
                >
                    <ModalHeader label="Confirm" title="Are you sure?" />
                    <ModalBody>
                        <p>Are you sure you want to create and finalize this stock flow?</p>
                        <p>IMPORTANT NOTE: No transactions have been created/specified for this stock flow</p>
                    </ModalBody>
                    <div style={{ height: 15 }} />
                    <ModalFooter>
                        <ButtonSet style={{ width: '100%' }}>
                            <Button kind="secondary" onClick={() => this.setState({ showNoTransactionNoticeDialog: false })}>
                                Cancel
                            </Button>
                            <Button onClick={() => {
                                this.createFlow(true)
                            }}>
                                Confirm
                            </Button>
                        </ButtonSet>
                    </ModalFooter>
                </ComposedModal>

                <ComposedModal
                    open={this.state.showCreateFlowConfirmDialog}
                    onClose={() => this.setState({ showCreateFlowConfirmDialog: false })}
                    size="sm"
                //style={{background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden'}}
                >
                    <ModalHeader label="Confirm" title="Are you sure?" />
                    <ModalBody>
                        <p>Are you sure you want to create and finalize this stock flow?</p>
                    </ModalBody>
                    <div style={{ height: 15 }} />
                    <ModalFooter>
                        <ButtonSet style={{ width: '100%' }}>
                            <Button kind="secondary" onClick={() => this.setState({ showCreateFlowConfirmDialog: false })}>
                                Cancel
                            </Button>
                            <Button onClick={() => {
                                this.createFlow(true)
                            }}>
                                Confirm
                            </Button>
                        </ButtonSet>
                    </ModalFooter>
                </ComposedModal>

                <div className="full-size-dialog">
                    <ItemCreatorDialog
                        fullSized
                        //filterProperties={["name", "email", "phoneNumber"]}
                        objectType={OBJECT_TYPE_PRODUCT}
                        open={this.state.showCreateProductDialog} onClose={() => this.setState({ showCreateProductDialog: false })}
                        onCreatedItem={createdItem => {
                            UIUtil.showSuccess();
                            this.setState({ showCreateProductDialog: false })
                            this.addProductToStockItemEditor(createdItem);
                        }} />
                </div>

                <ComposedModal
                    key="save-state-dialog"
                    open={this.state.showSaveDraftDialog}
                    onClose={() => this.setState({ showSaveDraftDialog: false })}
                    size="sm">
                    <ModalHeader label="Saving" title="Draft" />
                    <ModalBody style={{ paddingRight: '1rem' }}>
                        <TextInput
                            data-modal-primary-focus
                            value={this.state.saveDraftTitleValue}
                            onChange={e => this.setState({ saveDraftTitleValue: e.target.value })}
                            labelText="Title"
                            placeholder="Optional title"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <ButtonSet style={{ width: '100%' }}>
                            <Button kind="secondary" onClick={() => this.setState({ showSaveDraftDialog: false })} renderIcon={ErrorFilled16}>
                                Cancel
                            </Button>
                            <Button onClick={() => this.saveUpdateDraft()} renderIcon={Save16} loading={this.state.savingDraft}>
                                Save
                            </Button>
                        </ButtonSet>
                    </ModalFooter>
                </ComposedModal>
                <MyStateDraftsDialog open={this.state.showDrafts} onClose={() => this.setState({ showDrafts: false })} stateType={STATE_TYPE_STOCK_FLOW_CREATOR}
                    onLoadDraftRequest={draft => this.loadDraft(draft)} />

                <ImportItemDialog
                    //REPEATED
                    stockItemMode={{
                        destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue,
                        hideLabelBtn: this.props.posSalesReturnMode,
                        stockFlow: { destinationType: this.state.destinationTypeValue, sourceType: this.state.sourceTypeValue },

                        sourceId: this.state.sourceIdValue,
                        invBatchNos: this.state.invBatchNos, setInvBatchNos: invBatchNos => this.setState({ invBatchNos })
                    }}
                    onItemsImported={this.loadStockItemEditorItems.bind(this)} open={this.state.showImportItemsDialog} onClose={() => this.setState({ showImportItemsDialog: false })} />
            </div>
        )
    }

    getSourceTypeList() {
        switch (this.state.sourceTypeValue) {
            case SOURCE_TYPE_SUPPLIER: case SOURCE_TYPE_SUPPLIER + "":
                return this.state.suppliers;
            case SOURCE_TYPE_WAREHOUSE:
                return this.state.warehouses;
            case SOURCE_TYPE_STORE:
                return this.state.stores;
            case SOURCE_TYPE_VENUE:
                return this.state.venues;
            case SOURCE_TYPE_MOBILE_UNIT:
                return this.state.mobileUnits;
        }

        return undefined;
    }

    getDestinationTypeList() {
        if (this.isSourceVenue()) {
            return undefined
        }

        switch (this.state.destinationTypeValue) {
            //case DESTINATION_TYPE_SUPPLIER:case DESTINATION_TYPE_SUPPLIER + "":
            //return this.state.suppliers;
            case DESTINATION_TYPE_CUSTOMER:
                return this.state.customers;
            case DESTINATION_TYPE_WAREHOUSE:
                return this.state.warehouses;
            case DESTINATION_TYPE_STORE:
                return this.state.stores;
            case DESTINATION_TYPE_VENUE:
                return this.state.venues;
            case DESTINATION_TYPE_MOBILE_UNIT:
                return this.state.mobileUnits;
        }

        return undefined;
    }

    getSourceTypeName() {
        switch (this.state.sourceTypeValue) {
            case SOURCE_TYPE_SUPPLIER: case SOURCE_TYPE_SUPPLIER + "":
                return "Supplier";
            case SOURCE_TYPE_WAREHOUSE:
                return "Warehouse";
            case SOURCE_TYPE_STORE:
                return "Store";
            case SOURCE_TYPE_VENUE:
                return "Venue"
            case SOURCE_TYPE_MOBILE_UNIT:
                return "Mobile Unit"
        }

        return undefined;
    }

    getDestinationTypeName() {
        switch (this.state.destinationTypeValue) {
            case DESTINATION_TYPE_SUPPLIER: case DESTINATION_TYPE_SUPPLIER + "":
                return "Supplier";
            case DESTINATION_TYPE_CUSTOMER:
                return "Customer";
            case DESTINATION_TYPE_WAREHOUSE:
                return "Warehouse";
            case DESTINATION_TYPE_STORE:
                return "Store";
            case DESTINATION_TYPE_VENUE:
                return "Venue"
            case DESTINATION_TYPE_MOBILE_UNIT:
                return "Mobile Unit";
        }

        return undefined;
    }

}

export default withRouter(StockFlowCreatorPage);