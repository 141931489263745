import React, { useEffect, useState } from 'react'
import Page from '../../../base/Page';

import {
    WatsonHealthTextAnnotationToggle32,
    ErrorFilled16, Save16, LicenseDraft32,
    Location24,
    Book32, Phone32, ListBulleted32, Location32, Document32, ArrowLeft16, ArrowRight20,
    LogoFacebook32, LogoTwitter32, LogoInstagram32, LogoYoutube32, Mobile32, SendAlt32
} from '@carbon/icons-react'

import './SystemValuesPage.scss'
import Util from '../../../util/Util';
import { ButtonSet, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from 'carbon-components-react';
import Button from '../../../components/Button';
import Api from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';
import { hasCapabilitySupport } from '../../../app/Capabilities';






const VAL_GROUPS = [
    {
        name: "Social Media Links",
        values: {
            "usrsys_sml_facebook": {
                icon: LogoFacebook32,
                title: "Facebook",
                desc: "Link",
            },
            "usrsys_sml_twitter": {
                icon: LogoTwitter32,
                title: "Twitter",
                desc: "Link",
            },
            "usrsys_sml_youtube": {
                icon: LogoYoutube32,
                title: "YouTube",
                desc: "Link",
            },
            "usrsys_sml_instagram": {
                icon: LogoInstagram32,
                title: "Instagram",
                desc: "Link",
            },
            "usrsys_sml_telegram": {
                icon: SendAlt32,
                title: "Telegram",
                desc: "Link",
            },
            "usrsys_sml_whats_app": {
                icon: Mobile32,
                title: "WhatsApp",
                desc: hasCapabilitySupport("retailWebsite") ? "Link" : "Full phone number in international format. Omit any zeroes, brackets, or dashes. E.g. 97150xxxxxxx",
            },
        }
    },
    {
        name: "Website Info",
        values: {
            "usrsys_terms_of_service": {
                icon: ListBulleted32,
                title: "Terms",
                desc: "For online store",
            },
            "usrsys_online_store_footer": {
                icon: Document32,
                title: "Footer",
                desc: "Footer description",
            },
            "usrsys_online_store_footer_number": {
                icon: Phone32,
                title: "Number",
                desc: "Footer tel number",
            },
        }
    },
]






const valArr = values => {
    let arr = [];
    for (const value in values) {
        arr.push({
            key: value,
            ...values[value]
        })
    }
    return arr;
}


const ValueComponent = ({ icon, title, desc, onClick }) => {
    return (
        <div onClick={onClick} className="system-values-type-btn" style={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '1rem' }}>
            {React.createElement(icon, { style: { width: 115, height: 115 } })}
            <h2>{title}</h2>
            <p>{desc}</p>
        </div>
    )
}

const EditorModal = ({ visible, systemValue, onClose }) => {
    // const systemValue = VALUES[systemValueKey]
    const systemValueKey = systemValue.key;

    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    const [value, setValue] = useState("")

    const onUpdateBtn = () => {
        setUpdating(true);
        Api.setSystemValue(systemValueKey, value, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                onClose();
            } else {
                UIUtil.showError(response.message)
            }
            setUpdating(false)
        })
    }

    useEffect(() => {
        if (visible && Util.isStringExists(systemValueKey)) {
            setLoading(true)
            Api.getSystemValue(systemValueKey, response => {
                if (response.status === true) {
                    setValue(response.payload ? response.payload : "")
                    setLoading(false)
                } else {
                    onClose();
                    UIUtil.showError(response.message)
                }
            })
        }
    }, [visible, systemValueKey])

    if (!systemValue) {
        return null;
    }

    return (
        <ComposedModal
            key="note-editor-modal"
            open={visible}
            onClose={onClose}
            size="sm">
            <ModalHeader label="Editing" title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {React.createElement(systemValue.icon, { width: 24, height: 24 })}
                    <h4 style={{ marginLeft: '0.25rem' }}>{systemValue.title}</h4>
                </div>
            } />
            <ModalBody style={{ paddingRight: '1rem' }}>
                {loading ? (
                    <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
                        <InlineLoading />
                    </div>
                ) : (
                    <TextArea
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        labelText="Value"
                        placeholder="Input value" />
                )}

                {/* <div style={{height: '1rem'}} /> */}


            </ModalBody>
            <ModalFooter>
                <ButtonSet style={{ width: '100%' }}>
                    <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16} >
                        Cancel
                    </Button>
                    <Button onClick={onUpdateBtn} renderIcon={Save16} disabled={loading || updating}
                    >
                        Save
                    </Button>
                </ButtonSet>
            </ModalFooter>
        </ComposedModal>
    )
}

class DynamicContentPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            selectedValue: undefined
        }
    }

    get isEditingValue() {
        //return Util.isStringExists(this.state.selectedValue)
        return this.state.selectedValue !== undefined;
    }

    getLayout() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }} className="menu-tile">
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3rem' }}>
                        <LicenseDraft32 style={{ width: 64, height: 64, marginRight: '0.5rem' }} />
                        <div>
                            <h1>Dynamic Content</h1>
                            <p style={{ fontSize: 18 }}>Update and set dynamic content</p>
                        </div>
                    </div>

                    {VAL_GROUPS.map(group => (
                        <div key={group.name + "-system-value-group"} style={{ marginBottom: '3rem' }}>
                            <p style={{ opacity: 0.65, marginBottom: '1rem' }}>{group.name}</p>
                            <div style={{ width: '100%', height: 2, backgroundColor: 'black', marginBottom: '1rem' }} />
                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                {valArr(group.values)
                                    .map(value => <ValueComponent {...value} key={value.key + '-' + group.name + "-system-set-card"}
                                        onClick={() => this.setState({ selectedValue: value })} />)}
                            </div>
                        </div>
                    ))}

                    {/* <p style={{opacity: 0.65, marginBottom: '1rem'}}>Select system value</p>
                    <div style={{width: '100%', height: 2, backgroundColor: 'black', marginBottom: '1rem'}} />
                    <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', }}>
                        {VAL_ARR.map(value => <ValueComponent {...value} onClick={() => this.setState({ selectedValueKey: value.key })} />)}
                    </div> */}
                </div>

                {this.isEditingValue && <EditorModal
                    systemValue={this.state.selectedValue}
                    visible={this.isEditingValue} onClose={() => this.setState({ selectedValue: undefined })} />}
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

// const ValueComponent = ({ icon, title, desc, onClick }) => {
//     return (
//         <div onClick={onClick} className="system-values-type-btn" style={{ width: 250, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
//             {React.createElement(icon, { style: { width: 115, height: 115 } })}
//             <h2>{title}</h2>
//             <p>{desc}</p>
//         </div>
//     )
// }

// const EditorModal = ({ visible, systemValueKey, onClose }) => {
//     const systemValue = VALUES[systemValueKey]

//     const [loading, setLoading] = useState(false);
//     const [updating, setUpdating] = useState(false);

//     const [value, setValue] = useState("")

//     const onUpdateBtn = () => {
//         setUpdating(true);
//         Api.setSystemValue(systemValueKey, value, response => {
//             if (response.status === true) {
//                 UIUtil.showSuccess();
//                 onClose();
//             } else {
//                 UIUtil.showError(response.message)
//             }
//             setUpdating(false)
//         })
//     }

//     useEffect(() => {
//         if (visible && Util.isStringExists(systemValueKey)) {
//             setLoading(true)
//             Api.getSystemValue(systemValueKey, response => {
//                 if (response.status === true) {
//                     setValue(response.payload ? response.payload : "")
//                     setLoading(false)
//                 } else {
//                     onClose();
//                     UIUtil.showError(response.message)
//                 }
//             })
//         }
//     }, [visible, systemValueKey])

//     if (!systemValue) {
//         return null;
//     }

//     return (
//         <ComposedModal
//             key="note-editor-modal"
//             open={visible}
//             onClose={onClose}
//             size="sm">
//             <ModalHeader label="Editing" title={
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                     {React.createElement(systemValue.icon, { width: 24, height: 24 })}
//                     <h4 style={{ marginLeft: '0.25rem' }}>{systemValue.title}</h4>
//                 </div>
//             } />
//             <ModalBody style={{ paddingRight: '1rem' }}>
//                 {loading ? (
//                     <div className="centered-progress-bar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 90 }}>
//                         <InlineLoading />
//                     </div>
//                 ) : (
//                     <TextArea
//                         value={value}
//                         onChange={e => setValue(e.target.value)}
//                         labelText="Value"
//                         placeholder="Input value" />
//                 )}

//                 {/* <div style={{height: '1rem'}} /> */}


//             </ModalBody>
//             <ModalFooter>
//                 <ButtonSet style={{ width: '100%' }}>
//                     <Button kind="secondary" onClick={onClose} renderIcon={ErrorFilled16} >
//                         Cancel
//                     </Button>
//                     <Button onClick={onUpdateBtn} renderIcon={Save16} disabled={loading || updating}
//                     >
//                         Save
//                     </Button>
//                 </ButtonSet>
//             </ModalFooter>
//         </ComposedModal>
//     )
// }

// class DynamicContentPage extends Page {

//     constructor(props) {
//         super(props)

//         this.state = {
//             ...this.state,
//             selectedValueKey: ""
//         }
//     }

//     get isEditingValue() {
//         return Util.isStringExists(this.state.selectedValueKey)
//     }

//     getLayout() {
//         return (
//             <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
//                 <div style={{ width: '75vw' }} className="menu-tile">
//                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3rem' }}>
//                         <LicenseDraft32 style={{ width: 64, height: 64, marginRight: '0.5rem' }} />
//                         <div>
//                             <h1>Dynamic Content</h1>
//                             <p style={{ fontSize: 18 }}>Update and set dynamic content</p>
//                         </div>
//                     </div>
//                     {VAL_GROUPS.map(group => (
//                         <div key={group.name + "-online-store-dynamic-system-value-group"} style={{ marginBottom: '6rem' }}>
//                             <p style={{ opacity: 0.65, marginBottom: '1rem' }}>{group.name}</p>
//                             <div style={{ width: '100%', height: 2, backgroundColor: 'black', marginBottom: '1rem' }} />
//                             <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
//                                 {valArr(group.values)
//                                     .map(value => <ValueComponent {...value} key={value.key + '-' + group.name + "-system-set-card"}
//                                         onClick={() => this.setState({ selectedValue: value })} />)}
//                             </div>
//                         </div>
//                     ))}

//                     {/* 
//                     <p style={{ opacity: 0.65, marginBottom: '1rem' }}>Select system value</p>
//                     <div style={{
//                         width: '100%', height: 2,
//                         //height: this.isEditingValue ? 400 : 2, transition: '250ms', 
//                         backgroundColor: 'black', marginBottom: '1rem'
//                     }} />

//                     {//!this.isEditingValue && 
//                         <div style={{
//                             width: '100%', display: 'flex', flexWrap: 'wrap',
//                             //opacity: this.isEditingValue ? 0 : 1, transition: '250ms'
//                         }}>
//                             {VAL_ARR.map(value => <ValueComponent {...value} onClick={() => this.setState({ selectedValueKey: value.key })} />)}
//                         </div>} */}
//                 </div>

//                 {this.isEditingValue && <EditorModal
//                     systemValueKey={this.state.selectedValueKey}
//                     visible={this.isEditingValue} onClose={() => this.setState({ selectedValueKey: "" })} />}
//             </div>
//         )
//     }

//     isPageLoadable() {
//         return false;
//     }

// }

export default DynamicContentPage;