import { useEffect, useState } from "react"


import { Calendar16 } from '@carbon/icons-react'
import { STATUS_PENDING, getOrderAddress } from "../base/sales-order";
import Api from "../../../session/Api";
import ProfilePic from "../../../components/ProfilePic";
import { DiscountItem } from "./discount-item";
import Util from "../../../util/Util";
import { OrderPreferencesView } from "../../pos/restaurant/dialog/order-preferences";
import { REST_ORDER_TYPE } from "../../../domain/rest-sales-order";

const Header = ({ value, first }) => <p style={{ fontWeight: 'bold', color: 'black', marginTop: first ? 0 : '1rem' }}>{value}</p>

const FormField = ({ title, value }) => (
    <div style={{ marginBottom: '0.25rem' }}>
        <label className="bx--label" style={{ marginBottom: 0 }}>{title}</label>
        <p>{value}</p>
    </div>
)

const Item = ({ item, order }) => {
    //const hasMappings = item.groupToProductMap && item.groupToProductMap.length && item.groupToProductMap.length > 0;
    const hasMappings = item.groupToProductMap && item.groupToProductMap.length > 0;

    const isEventOrder = order.type === REST_ORDER_TYPE.CATERING || order.type === REST_ORDER_TYPE.BANQUET;

    return (
        <div style={{ marginBottom: 15, borderRadius: 15, boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)" }} elevation={0} variant="outlined">
            <div //style={{ width: '100%', borderBottom: 'solid', borderBottomWidth: 1, borderBottomColor: '#cecece' }}
            >
                <div style={{
                    display: 'flex', alignItems: 'center', height: '100%', paddingLeft: 15, paddingRight: 25, paddingTop: 15,
                    // paddingBottom: 15,
                    //paddingBottom: hasMappings ? 5 : 15
                    paddingBottom: 15
                }}>
                    {/* <Avatar src={Api.getThumbnail(item.itemType, item.id)}>{Util.getFirstLetter(item.name)}</Avatar> */}
                    <ProfilePic size={30} notProfile src={Api.getThumbnail(item.itemType, item.itemId)} />
                    <div style={{ flex: 1, marginLeft: 7 }}>
                        <h4>{item.name}</h4>
                        {/* <p style={{ fontSize: 12, opacity: 0.65 }}>AED {item.totalAmount}</p> */}
                        {/* <p>+9094324324</p> */}
                    </div>

                    {/* <p style={{ fontSize: 12, opacity: 0.65, marginTop: 0, marginRight: 0 }}>{item.quantity}x</p> */}
                </div>

                <div style={{ display: 'flex', width: '100%', }}>
                    <div style={{ paddingRight: 25 }}>
                        <div
                            style={{ paddingLeft: 10, paddingBottom: 15, paddingRight: 15, marginLeft: '0.25rem', marginBottom: 5 }}
                        >
                            <p style={{ fontSize: 12, opacity: 0.65 }}>
                                Quantity
                            </p>

                            <p style={{ fontSize: 14 }}>{item.quantity}x</p>
                        </div>
                    </div>
                    {!isEventOrder && <div style={{ paddingRight: 25 }}>
                        <div
                            style={{ paddingLeft: 10, paddingBottom: 15, paddingRight: 15, marginLeft: '0.25rem', marginBottom: 5 }}
                        >
                            <p style={{ fontSize: 12, opacity: 0.65 }}>
                                Total Amount
                            </p>

                            <p style={{ fontSize: 14 }}>AED {item.totalAmount}</p>
                        </div>
                    </div>}

                    {hasMappings && <>
                        {item.groupToProductMap.map((item) => (
                            <div style={{ paddingRight: 25 }}>
                                <div
                                    key={`${item.groupId}:g-p:${item.productId}`}
                                    //47 + 
                                    style={{ paddingLeft: 10, paddingBottom: 15, paddingRight: 15, marginLeft: '0.25rem', marginBottom: 5 }}
                                >
                                    <p style={{ fontSize: 12, opacity: 0.65 }}>
                                        {item.groupName}
                                    </p>

                                    <p style={{ fontSize: 14 }}>{item.productName}</p>
                                </div>
                            </div>
                        ))}
                    </>}
                </div>

                {/* {hasMappings && <>
                    {item.groupToProductMap.map((item) => (
                        <div
                            key={`${item.groupId}:g-p:${item.productId}`}
                            //47 + 
                            style={{ paddingLeft: 10, paddingBottom: 15, paddingRight: 15, marginLeft: '0.25rem', marginBottom: 5 }}
                        >
                            <p style={{ fontSize: 12, opacity: 0.65 }}>
                                {item.groupName}
                            </p>

                            <p style={{ fontSize: 14 }}>{item.productName}</p>
                        </div>
                    ))}
                </>} */}

            </div>
        </div>
    )
}

const AmountField = ({ title, value, large }) => large ? (
    <div style={{ marginBottom: '0.25rem' }}>
        <label className="bx--label" style={{ marginBottom: 0, fontSize: 14 }}>{title}</label>
        <h2>AED {value}</h2>
    </div>
) : (
    <div style={{ marginBottom: '0.25rem' }}>
        <label className="bx--label" style={{ marginBottom: 0 }}>{title}</label>
        <p>AED {value}</p>
    </div>

)

const AmountSummary = ({ summary }) => {
    return (
        <div>
            <AmountField title="Subtotal" value={summary.subtotal} />
            {/* <div style={{ height: 10 }} /> */}
            {summary.discount > 0 && <>
                <AmountField title="Discount" value={summary.discount} />
                {/* <div style={{ height: 10 }} /> */}
            </>}
            <AmountField title="Tax" value={summary.tax} />
            {/* <div style={{ height: 10 }} /> */}

            <AmountField title="Grand Total" value={summary.grandTotal} large />
        </div>
    )
}

const OrderDates = ({ row }) => (
    <div style={{ paddingBottom: '1rem' }}>
        <h6>Order date/time</h6>
        <p><Calendar16 /> {Util.getFullDate(row.dateCreated)}</p>
        {row.status == STATUS_PENDING ? (
            <>
                <div style={{ height: 5 }} />
                <h6>Preferred {row.type === REST_ORDER_TYPE.PICK_UP ? 'Pickup' : 'Delivery'} Date</h6>
                <p><Calendar16 /> {Util.getFullDate(row.preferredDeliveryDate)}</p>

                {row.requiresRentalPickup && row.preferredRentalPickupDate > 0 && <>
                    <div style={{ height: 5 }} />
                    <h6>Preferred Pickup Date</h6>
                    <p><Calendar16 /> {Util.getFullDate(row.preferredRentalPickupDate)}</p>
                </>}
            </>
        ) : <>

            {row.invoicedDate > 0 ? (<>
                <div style={{ height: 5 }} />
                <h6 style={{ color: 'green' }}>Invoiced Date</h6>
                <p style={{ color: 'green' }}><Calendar16 /> {Util.getFullDate(row.invoicedDate)}</p>
            </>) : (<>
                <div style={{ height: 5 }} />
                <h6>Target {row.type === REST_ORDER_TYPE.PICK_UP ? 'Pickup' : 'Delivery'} Date</h6>
                <p><Calendar16 /> {Util.getFullDate(row.targetDeliveryDate)}</p>
            </>)}

            {row.requiresRentalPickup && (row.targetRentalPickupDate > 0 || row.dateRentalStockReturned > 0) && <>

                {row.dateRentalStockReturned > 0 ? (<>
                    <div style={{ height: 5 }} />
                    <h6 style={{ color: 'green' }}>Picked up Date</h6>
                    <p style={{ color: 'green' }}><Calendar16 /> {Util.getFullDate(row.dateRentalStockReturned)}</p>
                </>) : (<>
                    <div style={{ height: 5 }} />
                    <h6 style={{ color: 'red' }}>Target Pickup Date</h6>
                    <p style={{ color: 'red' }}><Calendar16 /> {Util.getFullDate(row.targetRentalPickupDate)}</p>
                </>)}
            </>}
        </>}


    </div>
    // <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
    //     <h6>Order date/time</h6>
    //     <p><Calendar16 /> {Util.getFullDate(row.dateCreated)}</p>

    //     {row.status == STATUS_PENDING ? (
    //         <>
    //             <div style={{ height: 5 }} />
    //             <h6>Preferred Delivery Date</h6>
    //             <p><Calendar16 /> {Util.getFullDate(row.preferredDeliveryDate)}</p>

    //             {row.requiresRentalPickup && row.preferredRentalPickupDate > 0 && <>
    //                 <div style={{ height: 5 }} />
    //                 <h6>Preferred Pickup Date</h6>
    //                 <p><Calendar16 /> {Util.getFullDate(row.preferredRentalPickupDate)}</p>
    //             </>}
    //         </>
    //     ) : <>
    //         <div style={{ height: 5 }} />
    //         <h6>Target Delivery Date</h6>
    //         <p><Calendar16 /> {Util.getFullDate(row.targetDeliveryDate)}</p>

    //         {row.requiresRentalPickup && row.preferredRentalPickupDate > 0 && <>
    //             <div style={{ height: 5 }} />
    //             <h6 style={{ color: 'red' }}>Target Pickup Date</h6>
    //             <p style={{ color: 'red' }}><Calendar16 /> {Util.getFullDate(row.targetRentalPickupDate)}</p>
    //         </>}
    //     </>}
    // </div>
)

export default ({ salesOrder, summary, onUpdate }) => {
    const { shippingAddress: address } = summary;

    return (
        <div style={{ padding: '1rem' }}>
            <Header value={"Order Overview"} first />
            <OrderDates row={salesOrder} />

            <Header value={"Preferences / Note"} />
            <OrderPreferencesView order={salesOrder} />

            <Header value={"Order Details"} />
            <FormField title={"Preferred Payment Method"} value={summary.preferredPaymentMethodName} />

            {address && <FormField title={"Shipping Address Contact"} value={<>
                <p>{address.firstName + " " + address.lastName}</p>
                <p>{address.emailAddress}</p>
                <p>{address.phoneNumber}</p>
            </>} />}

            {address && <FormField title={"Shipping Address"} value={getOrderAddress(summary)} />}

            <Header value={"Applied Discounts"} />
            <div>
                {salesOrder.discounts.length > 0 ? (
                    salesOrder.discounts.map(discount => <DiscountItem key={discount.id} discount={discount} salesOrderId={salesOrder.id} onUpdate={onUpdate} />)
                ) : (
                    <div>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Nothing applied</p>
                    </div>
                )}
            </div>


            <Header value={"Items"} />
            <div style={{ padding: 5, marginTop: 10 }}>
                {summary.products.map(item => <Item key={item.id} item={item} order={salesOrder} />)}
            </div>


            {/* <FormField title={"test"} value={"Fewf"} /> */}



            <Header value={"Amount Summary"} />
            <AmountSummary summary={summary} />
        </div>
    )


}